import { useEffect, useState } from "react";

import MIXSkillPage from "./single-skill-page";
import MixFinishPage from "./single-finish";

import { submitMixSkillTest } from "../../../services/assessment";

import { useDispatch, useSelector } from "react-redux";
import { getQueryParams } from "../../../helpers";
import { AppConfigEvent } from "../../../redux/types/redux-event";

import TestHeader from "../assessment/test-header/TestHeader";
import {
  Container,
  PageContainer,
  PageHeader,
  Span,
  CheckBoxBlock,
  Checkbox,
  AcceptText,
  BeginBtn,
} from "./single-assessment-css-functions";

const MixBeginPage = () => {
  const answerSheet = useSelector((state) => state.assessment.answerSheet);

  const testId = useSelector((state) => state.assessment.testId);

  const assessmentType = useSelector(
    (state) => state.assessment.assessmentType
  );
  const [getId, setgetId] = useState("");
  useEffect(() => {
    const getIdfromparams = getQueryParams("id");
    console.log("getId", getId);
    setgetId(getIdfromparams);
  }, []);

  //65d4a8cf49bb524bed3ef73f
  const dispatch = useDispatch();
  const [assessmentId, setId] = useState(getQueryParams("id") || "");
  const [pageNumb, setPage] = useState(0);
  const [tstId, setTestId] = useState("");
  const [assessmntType, setType] = useState("MIX");
  const setData = (testId, type, pageNumber) => {
    setTestId(testId);
    setType(type);
    setPage(pageNumber);
  };
  const onTestSubmit = async () => {
    console.log("Check if begin ontestsubmit works");
    dispatch({ type: AppConfigEvent.START_LOADING });
    const res = await submitMixSkillTest({ testId: testId, answerSheet });
    console.log("res", res);
    if (!res) return;
    setData(testId, assessmentType, 2);
    dispatch({ type: AppConfigEvent.STOP_LOADING });
  };
  return (
    <Container>
      <TestHeader
        headerConfig={{
          ghLogo: true,
          searchBar: false,
          home: false,
          msg: false,
          notification: false,
          avatarControl: true,
          timeTicker: pageNumb === 0 || pageNumb === 1,
        }}
        onTestSubmit={onTestSubmit}
        pageNum={pageNumb}
      />
      {pageNumb === 0 && (
        <PageContainer>
          <PageHeader>
            {getId !== "65d4a8cf49bb524bed3ef73f" ? (
              <>Personality Assessment</>
            ) : null}
            {getId == "65d4a8cf49bb524bed3ef73f" ? <>Personality Type</> : null}
          </PageHeader>
          {getId == "65d4a8cf49bb524bed3ef73f" ? (
            <>
              <div className="row text-center mt-25">
                <h3 style={{ color: "#2b4eff" }}>The Personality Type Test</h3>
              </div>
              <div className="container">
                <p>
                  The Personality Type test is derived from similar principles
                  used in Myers-Briggs Type Indicator (MBTI). It defines your
                  personality preferences in four dimensions:
                </p>
                <p></p>
                <div>
                  <ul style={{ marginLeft: "20px" }}>
                    <li style={{ listStyleType: "disc" }}>
                      Where you focus your attention – Extraversion (E) or
                      Introversion (I)
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      The way you take in information – Sensing (S) or INtuition
                      (N)
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      How you make decisions – Thinking (T) or Feeling (F)
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      How you deal with the world – Judging (J) or Perceiving
                      (P)
                    </li>
                  </ul>
                  <p>
                    The four letters that make up your personality type can help
                    you to understand yourself and your interactions with
                    others. Please note that there is no right or wrong answer
                    here. So choose the options based on{" "}
                    <b>who you think you really are</b> rather than what you
                    want others to think about you.
                  </p>
                </div>
              </div>
            </>
          ) : null}

          {getId !== "65d4a8cf49bb524bed3ef73f" ? (
            <>
              {" "}
              <Span>
                {" "}
                Our Personality Assessment will help you understand where you
                stand on 5 key personality traits that are essential for your
                life and career:
              </Span>
              <Span>There are no right or wrong answers.</Span>
              <Span>
                Your honest assessment of yourself will help you identify your
                true personality
              </Span>
              <Span>
                This section is not timed. Typically it takes 5 minutes to
                finish this section
              </Span>
              <Span>
                Once you submit your responses, we will provide you a detailed
                scorecard with feedback
              </Span>
            </>
          ) : null}

          <BeginBtn
            assessmentId={!assessmentId}
            disabled={!assessmentId}
            onClick={() => setPage(1)}
          >
            Begin
          </BeginBtn>
        </PageContainer>
      )}
      {pageNumb === 1 && (
        <MIXSkillPage
          assessmentId={assessmentId}
          assessmentType={"MIX"}
          setPage={setData}
        />
      )}
      {pageNumb === 2 && (
        <MixFinishPage testId={tstId} assessmentType={assessmntType} />
      )}
    </Container>
  );
};

export default MixBeginPage;
