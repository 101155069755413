enum ModalType {
  EDIT = "EDIT",
  ADD = "ADD",
}

enum LangProficiency {
  BASIC = "BASIC",
  INTERMEDIATE = "INTERMEDIATE",
  EXPERT = "EXPERT",
}

enum AssessmentType {
  APTITUDE = "APTITUDE",
  SOFT_SKILL = "SOFT_SKILL",
  MIX = "MIX",
}

const ScoreCardConstants: any = {
  APTITUDE: "Aptitude",
  SOFT_SKILL: "Soft Skills",
  SELF_AWARENESS: "Self Awareness",
  COMMUNICATION: "Communication",
  PEOPLE_COLLABORATION: "Collaboration",
  STRESS_HANDLING: "Stress Handling",
  VERBAL: "Verbal",
  LOGICAL: "Logical",
  QUANTITATIVE: "Quantitative",
  DATA_INTERPRETATION: "Data Interpretation",
  EXTRAVERSION: "Extraversion",
  ALTRUISM: "Altruism",
  EMOTIONAL_COMPOSURE: "Emotional composure",
  ORDERLINESS: "Orderliness",
  OPENNESS: "Openness",
  QUIZ: "Quiz",
  EI: "EI",
  SN: "SN",
  TF: "TF",
  JP: "JP",
};
const MentorsData = [
  {
    name: "Shailaja",
    img: "/images/Shailaja.jpg",
    designation: "Product Manager, Ernst & Young",
    working: "/images/ey.jpg",
    linkedIn: "https://www.linkedin.com/in/shailajagupta/",
    details:
      "Shailaja is an IIM graduate currently working as a Product Manager for Ernst & Young",
  },
  {
    name: "Bhavesh Patel",
    img: "/images/unsplash_MQ2xYBHImKM.svg",
    designation: "Campus Master",
    working: "/images/wipro.svg",
    linkedIn: "https://www.linkedin.com/in/bhavesh81/",
    details:
      "Bhavesh is currently working as the Head of Campus Hiring for a very large IT setup and is responsible for hiring 40,000 freshers annually. He has 17 years of Talent Acquisition and HR experience and knows almost everything related to campus placement. Given his in-depth knowledge in what it takes to crack campus interviews, Bhavesh wants to mentor college students to bridge their skill gaps and become successful in landing their first campus job.",
  },
  {
    name: "Karishma Fafat",
    img: "/images/Karishma.jpg",
    designation: "Product Manager, Concerto.ai",
    working: "/images/concerto.jpg",
    linkedIn: "https://www.linkedin.com/in/karishmafafat/",
    details:
      "Karishma is an IIM graduate currently working as a Product Manager for Concerto.ai.",
  },
  {
    name: "Sakshi Bansal",
    img: "/images/unsplash_Zz5LQe-VSMY.svg",
    designation: "Analytics",
    working: "/images/Razorpay_logo.svg",
    linkedIn: "https://www.linkedin.com/in/bansal-sakshi/",
    details:
      "Sakshi is currently working as Product Analytics Manager at Razorpay. She has 10 years experience in analytics that spans across big and small organisation and various industries. Her deep expertise lies in product sense, business understanding and technical skills to help understand data. As a mentor she would love to help you get started with a career in analytics or navigate to the next level.",
  },
  {
    name: "Pralay Chakrabarti",
    img: "/images/pralay.jpg",
    designation: "HR Leader and Mentor",
    working: "/images/Greenhorn.svg",
    linkedIn: "https://www.linkedin.com/in/pralaychakrabarti/",
    details: "Pralay is an IIM graduate and a veteran HR Leader and Mentor",
  },
  {
    name: "Sudhir Kumar",
    img: "/images/unsplash_KIPqvvTOC1s.svg",
    designation: "Software Engineer",
    working: "/images/google.svg",
    linkedIn: "https://www.linkedin.com/in/sudhir-giri-71398278/",
    details:
      "Sudhir is currently working as a Software Engineer with Google. He has over 5 years experience and has worked with major brands wuch as Amazon, Paytm and Infosys. Sudhir believes in giving back to the community and has a passion towards mentoring students who want to pursue career in software engineering.",
  },
];

export {
  ModalType,
  LangProficiency,
  AssessmentType,
  ScoreCardConstants,
  MentorsData,
};
