import React, { useState } from "react";

import { toast } from "react-toastify";
import { signupv2 } from "../../services/user-service";
import { setDataInLocalStorage } from "../../browser-storage";
import { LocalStorageConstants } from "../../services/localstorageconstants";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../services/config";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
const StudentSignUpFormWithValidation = () => {
  const navigate = useNavigate();
  const [error, seterror] = useState();
  const [details, setDetails] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    mobile: "",
    registered_by_hostname: window.location.hostname,
  });

  const onSignupSuccess = ({ email, userName, _id, token }) => {
    setDataInLocalStorage(LocalStorageConstants.USER_DETAILS, {
      userId: _id,
      email: email,
      userName: userName,
    });
    setDataInLocalStorage(LocalStorageConstants.IS_LOGGED_IN, true);
    setDataInLocalStorage(LocalStorageConstants.TOKEN, token);

    //COMMENTING TILL WE GET EMAIL SUPPORT BACK
    // const emailresp = emailActivationLink(email).then((resp)=>{
    //   console.log(resp)
    //   emailrespstore = resp
    // }).catch(err=>{
    //   console.log(err)

    // })
    // if(emailrespstore){
    // addToast('Activation email sent, Please check your email', {
    //    appearance: 'success',
    //     autoDismiss: true,
    //   })
    // }
    // console.log(emailresp)
  };

  const submitHandler = async (e) => {
    console.log("clicked");
    e.preventDefault();
    if (
      !details ||
      !details.password ||
      !details.email ||
      !details.firstname ||
      !details.lastname ||
      !details.mobile
    )
      return;

    const res = await signupv2(
      details.email,
      details.password,
      details.firstname,
      details.lastname,
      details.mobile,
      details.registered_by_hostname
    )
      .then((resp) => {
        console.log(resp);
        toast.success(`Successfully Created`, {
          position: "top-left",
        });
        onSignupSuccess(resp);
        navigate("/student-login", { replace: true });
      })
      .catch((err) => {
        console.log(err);
        if (err) {
          toast.error(`Error : ${err.response}`, {
            position: "top-left",
          });
        }
      });
    console.log(res);
  };

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    username: "",
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleValidation = (event) => {
    const input = event.target.value.trim();
    const controlId = event.target.id;

    if (controlId === "email") {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
      if (!isValidEmail) {
        event.target.setCustomValidity("Please enter a valid email address.");
      } else {
        event.target.setCustomValidity("");
      }
    } else if (controlId === "mobileNumber") {
      const isValidMobileNumber = /^\d{10}$/.test(input);
      if (!isValidMobileNumber) {
        event.target.setCustomValidity("Mobile number must be 10 digits.");
      } else {
        event.target.setCustomValidity("");
      }
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated}>
      <Form.Group as={Col} md="12" controlId="firstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          value={details.firstname}
          onChange={(e) =>
            setDetails({
              ...details,
              firstname: e.target.value,
            })
          }
          type="text"
          placeholder="First Name"
          name="firstname"
          autocapitalize="none"
          required
        />
        <Form.Control.Feedback type="invalid">
          Please provide your first name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="12" controlId="lastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          value={details.lastname}
          onChange={(e) =>
            setDetails({
              ...details,
              lastname: e.target.value,
            })
          }
          type="text"
          placeholder="Last Name"
          name="lastname"
          autocapitalize="none"
          required
        />
        <Form.Control.Feedback type="invalid">
          Please provide your last name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="12" controlId="mobileNumber">
        <Form.Label>Mobile Number</Form.Label>
        <Form.Control
          type="tel"
          pattern="[0-9]{10}"
          placeholder="Enter your mobile number"
          onBlur={handleValidation}
          value={details.mobile}
          onChange={(e) => setDetails({ ...details, mobile: e.target.value })}
          name="mobile"
          required
        />
        <Form.Control.Feedback type="invalid">
          Mobile number must be 10 digits.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="12" controlId="email">
        <Form.Label>Email address</Form.Label>

        <Form.Control
          type="email"
          placeholder="Enter your email"
          aria-describedby="inputGroupPrepend"
          name="email"
          onChange={(e) =>
            setDetails({
              ...details,
              email: e.target.value.toLowerCase(),
            })
          }
          value={details.email}
          required
          autocapitalize="none"
          onBlur={handleValidation}
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid email address.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="12" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Enter your password"
          name="password"
          autoComplete="off"
          onChange={(e) =>
            setDetails({
              ...details,
              password: e.target.value,
            })
          }
          autocapitalize="none"
          required
        />
        <Form.Control.Feedback type="invalid">
          Please provide a password.
        </Form.Control.Feedback>
      </Form.Group>
      <Button type="button" onClick={submitHandler}>
        Submit
      </Button>
    </Form>
  );
};

export default StudentSignUpFormWithValidation;
