import React from "react";
import { Link } from "react-router-dom";

const social_links = ["social_facebook", "social_twitter", "social_pinterest"];

const footer_widget = [
  {
    col: "col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 offset-lg-0 col-md-2 offset-md-1 col-sm-5 offset-sm-1",
    title: "Quick Link",
    footer__link: [
      { name: "Our Mentors", link_to: "/mentors" },
      { name: "Jobs", link_to: "/jobs" },

      { name: "Join Us", link_to: "/become-mentor" },
      // { name: "For Recruiters", link_to: "/recuiter" },
      // { name: "About US", link_to: "/about" },
    ],
  },
  // {
  //   col: "col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6",
  //   title: "Platform",
  //   footer__link: [{ name: "About Us", link_to: "/about-us" }],
  // },
  {
    col: "col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6",
    title: "Important Links",
    footer__link: [
      { name: "Privacy Policy", link_to: "/privacy-policy" },
      {
        name: "Cancellation and Refund Policy",
        link_to: "/cancellation-and-refund-policy",
      },

      // {
      //   name: "Shipping and Delivery Policy",
      //   link_to: "/shipping and delivery-policy",
      // },

      { name: "Terms of Use", link_to: "/terms-and-condition-policy" },
    ],
  },
  {
    col: "col-xxl-3 col-xl-3 col-lg-3 offset-lg-0 col-md-3 offset-md-1 col-sm-6",
    title: "Contact Us",
    footer__link: [
      { name: "Mail: hello@mygreenhorn.com", link_to: "#" },
      {
        name: "Phone: +917219437021",
        link_to: "#",
      },
      {
        name: "Address: 21/2 Whitefield, Bangalore -560066, Karnataka, India ",
        link_to: "#",
      },
    ],
  },
];

const Footer = ({ gray_bg, pt = "pt-190" }) => {
  return (
    <>
      <footer>
        <div className={`footer__area ${gray_bg ? "grey-bg-2" : "footer-bg"}`}>
          <div className={`footer__top pt-30 pb-10`}>
            <div className="container">
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div className="footer__widget mb-50">
                    <div className="footer__widget-head mb-22">
                      <div className="footer__logo">
                        {/* <Link to="/">
                          <img
                            src={
                              gray_bg
                                ? "/assets/img/logo/logo.svg"
                                : "/assets/img/logo/logo.svg"
                            }
                            alt="logo"
                          />
                        </Link> */}
                      </div>
                    </div>
                    <div
                      className={`footer__widget-body ${
                        gray_bg ? "footer__widget-body-2" : ""
                      }`}
                    >
                      <p>
                        Mygreenhorn Technology Private Limited is an
                        analytics-driven smart platform helping students and
                        working professionals land their dream career by
                        developing their core skills and competencies.
                      </p>
                      {/* <div className="footer__social">
                        <ul>
                          {social_links.map((link, index) => (
                            <li key={link}>
                              <a
                                href="#"
                                className={`${index === 1 && "tw"} ${
                                  index === 2 && "pin"
                                }`}
                              >
                                <i className={link}></i>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div className="footer__widget mb-50">
                    <div className="footer__widget-head mb-22">
                      <h3
                        className={`footer__widget-title ${
                          gray_bg ? "footer__widget-title-2" : ""
                        }`}
                      >
                        Important Links
                      </h3>
                    </div>
                    <div className="footer__widget-body">
                      <li>Privacy Policy</li>
                    </div>
                  </div>
                </div> */}

                {footer_widget.map((widget, index) => (
                  <div key={index} className={widget.col}>
                    <div className="footer__widget mb-50">
                      <div className="footer__widget-head mb-22">
                        <h3
                          className={`footer__widget-title ${
                            gray_bg ? "footer__widget-title-2" : ""
                          }`}
                        >
                          {widget.title}
                        </h3>
                      </div>
                      <div className="footer__widget-body">
                        <div
                          className={`footer__link ${
                            gray_bg ? "footer__link-2" : ""
                          }`}
                        >
                          <ul>
                            {widget.footer__link.map((link, index) => (
                              <li key={index}>
                                <Link to={link.link_to}>{link.name}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6">
                  <div className="footer__widget footer__pl-70 mb-50">
                    <div className="footer__widget-head mb-22">
                      <h3
                        className={`footer__widget-title ${
                          gray_bg ? "footer__widget-title-2" : ""
                        }`}
                      >
                        Subscribe
                      </h3>
                    </div>
                    <div className="footer__widget-body">
                      <div
                        className={`footer__subscribe ${
                          gray_bg ? "footer__subscribe-2" : ""
                        }`}
                      >
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="footer__subscribe-input mb-15">
                            <input
                              type="email"
                              placeholder="Your email address"
                            />
                            <button type="submit">
                              <i className="far fa-arrow-right"></i>
                              <i className="far fa-arrow-right"></i>
                            </button>
                          </div>
                        </form>
                        <p>
                          Get the latest news and updates right at your inbox.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div
            className={`footer__bottom ${gray_bg ? "footer__bottom-2" : ""}`}
          >
            <div className="container">
              <div className="row">
                <div className="col-xxl-12">
                  <div
                    className={`footer__copyright ${
                      gray_bg ? "footer__copyright-2" : ""
                    } text-center`}
                  >
                    <p>
                      © {new Date().getFullYear()} MyGreenHorn, All Rights
                      Reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
