const about_info = {
  reviews: "8,200+",
  about_name: "Depikka Siingh",
  about_img: "/assets/img/WhiteLabled/about/1.jpeg",
  about_banner: "/assets/img/about/about-banner.jpg",
  students_imgs: [
    "/assets/img/about/student-4.jpg",
    "/assets/img/about/student-3.jpg",
    "/assets/img/about/student-2.jpg",
    "/assets/img/about/student-1.jpg",
  ],
  students: "",
  title: <>{/* Achieve your <br /> */}</>,
  shape_text: "",
  title_2: "About me",
  desc: "I'm Maanu Bhandari, your guide on an exciting journey of learning, growth, and personal transformation. With a remarkable 25-year career as a Learning and Development Specialist, I've had the privilege of working with a diverse range of clients who have experienced the power of education in new and impactful ways. ",
  sub_desc: "",
  about_list: [
    "10+ years of experience in hiring and training with EY, J P Morgan, Aditya Birla, Kotak, Barclays. Currently a hiring expert for 9 global organisations. ",
    "Helped over 3000 working professionals get placed.",
    "Hundreds of students landed their dream jobs and some have received more than 150% salary hike!",
  ],
};

const WhiteAboutArea = ({ padd = "pt-90 pb-90" }) => {
  return (
    <>
      <section style={{ paddingBottom: 50 }} className={`about__area ${padd}`}>
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <img
                width={250}
                height={250}
                style={{ borderRadius: "50%" }}
                src={about_info.about_img}
              />
              <p className="pt-10" style={{ fontSize: 18, fontWeight: 600 }}>
                {about_info.about_name}
              </p>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="about__content pl-70 pr-60 pt-25">
                <div className="section__title-wrapper mb-25">
                  <h2 style={{ fontSize: 36 }} className="section__title">
                    {about_info.title}
                    <span className="yellow-bg-big">
                      {about_info.shape_text}{" "}
                      <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                    </span>{" "}
                    {about_info.title_2}{" "}
                  </h2>
                  {/* <p style={{ textAlign: "left" }}>{about_info.desc}</p> */}
                  <p style={{ textAlign: "left" }}>
                    {about_info.about_list.map((item) => {
                      return (
                        <>
                          <p
                            className="pt-10"
                            style={{ fontSize: 18, fontWeight: 400 }}
                          >
                            {item}
                          </p>
                        </>
                      );
                    })}
                  </p>
                  <p style={{ textAlign: "left" }} className="pt-20">
                    {about_info.sub_desc}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhiteAboutArea;
