import { Link } from "react-router-dom";
import {
  Academics,
  ArtDesign,
  Business,
  DataScience,
  Finance,
  HealthFitness,
  Lifestyle,
  Marketing,
  Music,
} from "../../svg";

const category_data = [
  {
    id: 1,
    icon: <DataScience />,
    title: "Career Roadmap",
    subtitle:
      "Explore your career options and analyze your personality and skills to choose the most suitable one",
  },
  {
    id: 2,
    icon: <Business />,
    title: "Personal Branding",
    subtitle:
      "Groom yourself and learn techniques to create a strong impression with your stakeholders",
  },
  {
    id: 3,
    icon: <ArtDesign />,
    title: "Persuasive Communication",
    subtitle:
      "Learn techniques to articulate with confidence in one to one and group settings",
  },
  {
    id: 4,
    icon: <Lifestyle />,
    title: "Interview Preparation",
    subtitle:
      "Learn everything from ATS Resume Building to FAQ in order to bag your next big career opportunity",
  },
  {
    id: 5,
    icon: <Marketing />,
    title: "Profile Building",
    subtitle:
      "From resume to LinkedIn, get yourself noticed by building a powerful profile and a smart elevator pitch",
  },
  {
    id: 6,
    icon: <Finance />,
    title: "Cognitive Analysis",
    subtitle:
      "Interact with our Artificial Intelligence Bot to improve your interpersonal skills and advance your career",
  },
];

const CategoryArea = () => {
  return (
    <>
      <section className="category__area pt-120 pb-70">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
              <div className="section__title-wrapper mb-45">
                <h2 className="section__title">
                  <span className="yellow-bg">
                    What we help you with{" "}
                    <img src="/assets/img/shape/yellow-bg-2.png" alt="" />{" "}
                  </span>
                </h2>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4"></div>
          </div>
          <div className="row">
            {category_data.map((category) => (
              <div
                key={category.id}
                className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6"
              >
                <div
                  style={{ padding: 20 }}
                  className="category__item mb-30 transition-3 d-flex align-items-center"
                >
                  <div className="category__icon mr-30">{category.icon}</div>
                  <div className="category__content">
                    <h4 className="category__title">{category.title}</h4>
                    <p>{category.subtitle}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryArea;
