import { useEffect, memo } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { AssessmentTest } from "../../../../../redux/types/redux-event";

import {
  getAvailableAssessment,
  getModifiedAvailableAssessment,
} from "../../../../../services/assessment";
import AssessmentList from "./assessment-list";
//import MobilePage from "components/common/mobile-page";
//import "../../dashboard.css";
//import MobilePageRedirect from "components/common/mobile-page/mobileRedirect";

import {
  GET_AVAILABLE_ASSESSMENT,
  GET_MODIFIED_AVAILABLE_ASSESSMENT,
} from "../../../../../redux/features/available-assessment-slice";
const AvailableAssessment = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getModifiedAvailableAssessment()
      .then((data) => {
        console.log(data);
        dispatch(GET_AVAILABLE_ASSESSMENT(data));
      })
      .catch(() =>
        toast.error(`Unable to get your assessments `, {
          position: "top-left",
        })
      );
  }, []);
  return (
    <>
      <AssessmentList />
    </>
  );
};

export default memo(AvailableAssessment);
