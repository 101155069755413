import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Options } from "../../../redux/types";
import { useDispatch, useSelector } from "react-redux";

import ConfirmationModal from "../assessment/common/confirmationModal";
import { AssessmentType } from "../../../redux/types/generic-constant";
import {
  createModifiedMixSkillTest,
  submitMixSkillTest,
} from "../../../services/assessment";
import {
  removeDataFromSessionStorage,
  setDataInSessionStorage,
} from "../../../browser-storage";
import { SessionStorageConstants } from "../../../redux/types/storage-constant";
import { AssessmentTest } from "../../../redux/types/redux-event/assessment";
import { AppConfigEvent } from "../../../redux/types/redux-event";
import {
  ASSESSMENT_TEST_START,
  UPDATE_ANSWER_SHEET,
} from "../../../redux/features/assessment-slice";
import {
  PageBlock,
  Container,
  PageHeader,
  PageContainer,
  AnswerTrack,
  QuestionTracking,
  NumberOfQuestions,
  RoundAnswer,
  MobileRoundAnswer,
  SpanText,
  AnswerTrackBlock,
  QuestionTrackBlock,
  Btn,
  MobBtn,
  Answer,
  RadioBtn,
  MobRadioBtn,
  AnswerPanel,
  QuestionImg,
  Answers,
  AnswerBlock,
  Question,
  BtnBlock,
  ProgressBarChildDiv,
  ProgressBarParentDiv,
} from "../assessment/skill-page-css-functions";
import { getQueryParams } from "../../../helpers";
const MIXSkillPage = (props) => {
  console.log("on skill page");
  const dispatch = useDispatch();
  const [isOpen, togglePopup] = useState(false);
  const [isFullscreen, fullscreenPopup] = useState(true);
  const [isAnswerAll, setPopup] = useState(false);
  const [queNumber, changeQuestionNumber] = useState(0);
  const [screenResize, setScreenResize] = useState(0);

  const answerSheet = useSelector((state) => state.assessment.answerSheet);
  const testId = useSelector((state) => state.assessment.testId);
  const questions = useSelector((state) => state.assessment.questions);
  //console.log("answerSheet", answerSheet);
  //console.log("testId", testId);
  //console.log("questions", questions);

  const [getId, setgetId] = useState("");
  useEffect(() => {
    const getIdfromparams = getQueryParams("id");
    // console.log("getId", getId);
    setgetId(getIdfromparams);
  }, []);
  const onTogglePopup = () => {
    togglePopup((prevOpenState) => !prevOpenState);
  };
  const setSoftAnswerPopup = () => {
    setPopup((prevState) => !prevState);
  };
  const startTest = async () => {
    dispatch({ type: AppConfigEvent.START_LOADING });

    const res = await createModifiedMixSkillTest(props.assessmentId);

    if (!res) return;
    dispatch({ type: AppConfigEvent.STOP_LOADING });

    //console.log("res", res);
    dispatch(
      ASSESSMENT_TEST_START({
        questions: res?.questions,
        answerSheet: res.answerSheet,
        testId: res.testId,
        assessmentType: props.assessmentType,
      })
    );

    setDataInSessionStorage(res, SessionStorageConstants.ASSESSMENT_TEST);
  };
  useEffect(() => {
    startTest();
  }, []);
  useEffect(() => {
    checkScreenResize();
  }, [screenResize]);
  const radioButton = (index) => {
    let newAnswerSheet = { ...answerSheet };
    newAnswerSheet[selectedQuestion.questionId] = index;

    dispatch(UPDATE_ANSWER_SHEET({ answerSheet: newAnswerSheet }));
  };
  const answerValidation = () => {
    if (props.assessmentType == "MIX") {
      for (let i = 0; i <= Object.keys(answerSheet).length; i++) {
        if (answerSheet[i] === -1) {
          setPopup((prevState) => !prevState);
          return false;
        }
      }
      return true;
    }
    return true;
  };
  const onTestSubmit = async () => {
    if (!answerValidation()) return onTogglePopup();
    dispatch({ type: AppConfigEvent.START_LOADING });

    const res = await submitMixSkillTest({
      testId: testId,
      answerSheet,
    });

    if (!res) return;
    dispatch({ type: AppConfigEvent.STOP_LOADING });
    dispatch({
      type: "MIX_SKILL_TEST_SUBMITTED",
      data: { result: res.result, testId: res.testId },
    });
    removeDataFromSessionStorage(SessionStorageConstants.ASSESSMENT_TEST);
    props.setPage(testId, props.assessmentType, 2);
    // return true
  };

  const checkScreenResize = () => {
    if (myStateRef.current >= 3) onTestSubmit();
    fullscreenPopup(true);
  };
  const setMyState = (data) => {
    myStateRef.current = data;
    setScreenResize(data);
  };
  const myStateRef = React.useRef(screenResize);
  const nextQuestion = () => {
    if (questions.length === queNumber + 1) return onTogglePopup();
    changeQuestionNumber(queNumber + 1);
    let newQueArray = [...questions];
    newQueArray[queNumber].key = true;
    dispatch({
      type: AssessmentTest.UPDATE_QUESTIONS,
      data: { questions: newQueArray },
    });
  };
  const reachedQuestions = queNumber + 1;
  const fullscreen = () => {
    document.body
      .requestFullscreen()
      .then((response) => console.log("response", response))
      .catch((err) => console.log("response", err));
  };
  useEffect(() => {
    console.log(" props.assessmentType", props.assessmentType);
    const fullscreen = () => {
      if (document.fullscreenElement) return fullscreenPopup(false);
      else {
        setMyState(myStateRef.current + 1);
        fullscreenPopup(true);
      }
    };
    document.addEventListener("fullscreenchange", fullscreen);
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("fullscreenchange", fullscreen);
    };
  }, []);
  useEffect(() => {
    const beforeunloads = (e) => {
      e.preventDefault();
      e.returnValue = "";
      setMyState(myStateRef.current + 1);
      onTestSubmit();
    };
    window.addEventListener("beforeunload", beforeunloads);
    return () => {
      window.removeEventListener("beforeunload", beforeunloads);
    };
  }, []);
  const selectedQuestion = questions && questions[queNumber];
  return (
    <>
      <div className="desktop">
        <PageBlock id={"goFS"} onClick={fullscreen}>
          <ConfirmationModal
            isOkBtnShow={true}
            headerText={
              "If you minimized your window more than twice,you will not allowed to continue test further and your answer will be submitted instantly."
            }
            isOpen={isFullscreen}
            onOkBtnClick={fullscreen}
          />
          <ConfirmationModal
            isOkBtnShow={true}
            isCancelBtnShow={true}
            headerText={"Are you sure you want to submit your answers? "}
            isOpen={isOpen}
            onCancelClick={onTogglePopup}
            onOkBtnClick={onTestSubmit}
          />
          <ConfirmationModal
            isOkBtnShow={true}
            isCancelBtnShow={false}
            headerText={"You need to answer all questions mandatorily."}
            isOpen={isAnswerAll}
            onOkBtnClick={setSoftAnswerPopup}
          />
          <Container>
            <PageContainer>
              <PageHeader>
                {" "}
                {getId !== "65d4a8cf49bb524bed3ef73f" ? (
                  <>Personality Assessment</>
                ) : null}
                {getId == "65d4a8cf49bb524bed3ef73f" ? (
                  <>Personality Type</>
                ) : null}
              </PageHeader>
              <PageHeader>
                {" "}
                Question {reachedQuestions} of {questions && questions?.length}
              </PageHeader>
              <ProgressBarParentDiv>
                <ProgressBarChildDiv
                  widthPer={`${
                    (reachedQuestions / (questions && questions?.length)) * 100
                  }%`}
                >
                  {" "}
                </ProgressBarChildDiv>
              </ProgressBarParentDiv>
              <>
                <Question>{selectedQuestion?.questionText}</Question>
                <AnswerBlock>
                  <Answers>
                    {selectedQuestion?.options?.map((option, index) => (
                      <AnswerPanel>
                        <RadioBtn
                          key={index}
                          onClick={() => radioButton(index)}
                          isChecked={
                            answerSheet[selectedQuestion.questionId] === index
                          }
                        />
                        <Answer>
                          {Options[index] + "."} {option}
                        </Answer>
                      </AnswerPanel>
                    ))}
                  </Answers>
                  {selectedQuestion?.questionImg && (
                    <QuestionImg
                      src={selectedQuestion.questionImg}
                      alt={"question"}
                    />
                  )}
                </AnswerBlock>
                <BtnBlock>
                  <Btn
                    onClick={() =>
                      changeQuestionNumber(
                        queNumber === 0 ? queNumber : queNumber - 1
                      )
                    }
                  >
                    Back
                  </Btn>
                  <Btn onClick={() => nextQuestion()}>
                    {queNumber === (questions && questions.length) - 1
                      ? "Finish"
                      : "Next"}
                  </Btn>
                </BtnBlock>
              </>
            </PageContainer>
            <QuestionTrackBlock>
              <QuestionTracking>
                <AnswerTrackBlock>
                  <RoundAnswer answerType={AnswerTrack.ANSWERED_COLOR} />
                  <SpanText> Answered</SpanText>
                </AnswerTrackBlock>
                <AnswerTrackBlock>
                  <RoundAnswer answerType={AnswerTrack.NOT_VISITED_COLOR} />
                  <SpanText>Not Visited</SpanText>
                </AnswerTrackBlock>
                <AnswerTrackBlock>
                  <RoundAnswer answerType={AnswerTrack.NOT_ANSWERED_COLOR} />
                  <SpanText>Not Answered</SpanText>
                </AnswerTrackBlock>
              </QuestionTracking>
              <NumberOfQuestions>
                {questions &&
                  questions.map((obj, index) => (
                    <RoundAnswer
                      key={index}
                      onClick={() => changeQuestionNumber(index)}
                      answerType={
                        answerSheet[obj.questionId] !== -1
                          ? AnswerTrack.ANSWERED_COLOR
                          : answerSheet[obj.questionId] === -1 && obj.key
                          ? AnswerTrack.NOT_ANSWERED_COLOR
                          : AnswerTrack.NOT_VISITED_COLOR
                      }
                    >
                      {index + 1}
                    </RoundAnswer>
                  ))}
              </NumberOfQuestions>
            </QuestionTrackBlock>
          </Container>
        </PageBlock>
      </div>
      <div className="mobile">
        <ConfirmationModal
          isOkBtnShow={true}
          isCancelBtnShow={true}
          headerText={"Are you sure you want to submit your answers? "}
          isOpen={isOpen}
          onCancelClick={onTogglePopup}
          onOkBtnClick={onTestSubmit}
        />
        <ConfirmationModal
          isOkBtnShow={true}
          isCancelBtnShow={false}
          headerText={"You need to answer all questions mandatorily."}
          isOpen={isAnswerAll}
          onOkBtnClick={setSoftAnswerPopup}
        />
        <div style={{ padding: 10 }}>
          <div>
            <PageHeader>
              {" "}
              {getId !== "65d4a8cf49bb524bed3ef73f" ? (
                <>Personality Assessment</>
              ) : null}
              {getId == "65d4a8cf49bb524bed3ef73f" ? (
                <>Personality Type</>
              ) : null}
            </PageHeader>
            <div
              className="answer-completed-area"
              style={{ whiteSpace: "nowrap" }}
            >
              <div style={{ width: "33.33%", display: "inline-block" }}>
                <AnswerTrackBlock>
                  <RoundAnswer answerType={AnswerTrack.ANSWERED_COLOR} />
                  <SpanText> Answered</SpanText>
                </AnswerTrackBlock>
              </div>
              <div
                className="text-center"
                style={{ width: "33.33%", display: "inline-block" }}
              >
                <AnswerTrackBlock>
                  <RoundAnswer answerType={AnswerTrack.NOT_VISITED_COLOR} />
                  <SpanText>Not Visited</SpanText>
                </AnswerTrackBlock>
              </div>
              <div style={{ width: "33.33%", display: "inline-block" }}>
                <AnswerTrackBlock>
                  <RoundAnswer answerType={AnswerTrack.NOT_ANSWERED_COLOR} />
                  <SpanText>Not Answered</SpanText>
                </AnswerTrackBlock>
              </div>
            </div>

            <div>
              <div
                className="NumberOfQuestions"
                style={{
                  display: "flex",
                  flexDirection: "row",

                  flexWrap: "wrap",
                  gap: "0px 5px",
                  marginTop: "5px",
                  overflow: "auto",
                }}
              >
                {/* Your content here */}
                {questions &&
                  questions.map((obj, index) => (
                    <MobileRoundAnswer
                      key={index}
                      onClick={() => changeQuestionNumber(index)}
                      answerType={
                        answerSheet[obj.questionId] !== -1
                          ? AnswerTrack.ANSWERED_COLOR
                          : answerSheet[obj.questionId] === -1 && obj.key
                          ? AnswerTrack.NOT_ANSWERED_COLOR
                          : AnswerTrack.NOT_VISITED_COLOR
                      }
                    >
                      {index + 1}
                    </MobileRoundAnswer>
                  ))}
              </div>

              {/* <NumberOfQuestions></NumberOfQuestions> */}
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                fontSize: "22px",
                color: "#3e3d43",
                paddingBottom: "15px",
                marginTop: 15,
              }}
            >
              {" "}
              Question {reachedQuestions} of {questions && questions?.length}
            </div>
            <ProgressBarParentDiv>
              <ProgressBarChildDiv
                widthPer={`${
                  (reachedQuestions / (questions && questions?.length)) * 100
                }%`}
              >
                {" "}
              </ProgressBarChildDiv>
            </ProgressBarParentDiv>
            <>
              <Question>{selectedQuestion?.questionText}</Question>
              {selectedQuestion?.questionImg && (
                <QuestionImg
                  src={selectedQuestion.questionImg}
                  alt={"question"}
                />
              )}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  padding: "5px 0px 0px",
                }}
              >
                <div
                  style={{
                    maxWidth: "550px",
                  }}
                >
                  {selectedQuestion?.options?.map((option, index) => (
                    <div
                      style={{
                        marginRight: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      <div class="d-flex flex-row bd-highlight">
                        <div class="p-2 bd-highlight">
                          {" "}
                          <div style={{ marginTop: "5px" }}>
                            <MobRadioBtn
                              key={index}
                              onClick={() => radioButton(index)}
                              isChecked={
                                answerSheet[selectedQuestion.questionId] ===
                                index
                              }
                            />
                          </div>
                        </div>
                        <div class="p-2 bd-highlight">
                          {" "}
                          <Answer>
                            {Options[index] + "."} {option}
                          </Answer>
                        </div>
                      </div>
                    </div>

                    // <AnswerPanel>
                    //   <RadioBtn
                    //     key={index}
                    //     onClick={() => radioButton(index)}
                    //     isChecked={
                    //       answerSheet[selectedQuestion.questionId] === index
                    //     }
                    //   />
                    //   <Answer>
                    //     {Options[index] + "."} {option}
                    //   </Answer>
                    // </AnswerPanel>
                  ))}
                </div>
              </div>
              <BtnBlock>
                <MobBtn
                  onClick={() =>
                    changeQuestionNumber(
                      queNumber === 0 ? queNumber : queNumber - 1
                    )
                  }
                >
                  Back
                </MobBtn>
                <MobBtn onClick={() => nextQuestion()}>
                  {queNumber === (questions && questions.length) - 1
                    ? "Finish"
                    : "Next"}
                </MobBtn>
              </BtnBlock>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default MIXSkillPage;
