import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { menu_bar, selectMenuBar } from "../../redux/features/header-slice";
import { Search } from "../../svg";

const StudentMenuData = [
  {
    MenuTitle: "Home",
    MenuLink: "/",
  },
  {
    MenuTitle: "Dashboard",
    MenuLink: "/student-dashboard",
  },
];
const GuestMenuData = [
  {
    MenuTitle: "Home",
    MenuLink: "/",
  },
  {
    MenuTitle: "Our Mentors",
    MenuLink: "/mentors",
  },
];

const Sidebar = ({ currentRoleHeader }) => {
  const menuOpen = useSelector(selectMenuBar);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log("currentRoleHeader", currentRoleHeader);
  const handleLogout = () => {
    dispatch(menu_bar(false));
    localStorage.removeItem("user");
    localStorage.removeItem("_user");
    localStorage.removeItem("user-details");
    navigate("/student-login", { replace: true });
  };
  return (
    <>
      <div className={menuOpen ? "sidebar__area open" : "sidebar__area"}>
        <div className="sidebar__wrapper">
          <div className="sidebar__close">
            <button
              className="sidebar__close-btn"
              id="sidebar__close-btn"
              onClick={() => dispatch(menu_bar(false))}
            >
              <span>
                <i className="far fa-times"></i>
              </span>
              <span>close</span>
            </button>
          </div>
          <div className="sidebar__content">
            <div className="logo mb-40">
              <Link to="/">
                <img src="/assets/img/logo/GH1.png" alt="logo" />
              </Link>
            </div>
            {currentRoleHeader == "STUDENT" ? (
              <ul>
                {StudentMenuData.map((link, index) => (
                  <li
                    onClick={() => dispatch(menu_bar(false))}
                    key={index}
                    className=""
                  >
                    <Link to={`${link.MenuLink}`}>{link.MenuTitle}</Link>
                  </li>
                ))}
              </ul>
            ) : (
              <>
                <ul>
                  {GuestMenuData.map((link, index) => (
                    <li key={index} className="">
                      <Link to={`${link.MenuLink}`}>{link.MenuTitle}</Link>
                    </li>
                  ))}
                </ul>

                <Link
                  onClick={() => dispatch(menu_bar(false))}
                  to="/student-login"
                  className="e-btn"
                >
                  Sign in
                </Link>
              </>
            )}

            {currentRoleHeader == "STUDENT" ? (
              <div onClick={() => handleLogout()} className="e-btn">
                Logout
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* <!-- sidebar overlay end --> */}
      <div
        onClick={() => dispatch(menu_bar(false))}
        className={`body-overlay ${menuOpen ? "opened" : ""}`}
      ></div>
      {/* <!-- sidebar overlay end --> */}
    </>
  );
};

export default Sidebar;
