import { useState } from "react";
import { useSelector } from "react-redux";
//import { RootState } from 'redux/store'
import { ModalType } from "../../../../../redux/types/generic-constant";
import styled from "styled-components";
import { DetailTab } from "../../common";
import { LangProficiency } from "../../../../../redux/types/generic-constant";

import AddEditLanguageModal from "./add-edit-language-modal";
import "../../profile.css";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const LanguageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  &:hover {
    img {
      opacity: 1;
    }
  }
  img {
    padding-top: 6px;
    opacity: ${({ idx }) => (idx === 0 ? 1 : 0)};
    cursor: pointer;
  }
`;

const LanguageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(61, 41, 36, 0.8) !important;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 20px;
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 16px;
  }
`;

const PillGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding-top: 6px;
`;

const PillContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 70px;
  font-family: Poppins-Regular;
  font-style: normal;
  font-size: 14px;
  color: rgba(1, 103, 56, 0.94);
  div:nth-child(1) {
    -webkit-transition: background-color 1.5s linear;
    -ms-transition: background-color 1.5s linear;
    transition: background-color 1.5s linear;
    background-color: ${({ isDisabled }) =>
      isDisabled ? "#C4C4C4" : "#17b978"};
  }
`;

const Pill = styled.div`
  width: 40px;
  height: 17px;
  border-radius: 8px;
`;
const Languages = () => {
  const languages = useSelector((state) => state.profile.languages);
  //console.log("languages", languages);
  const [openModalType, setOpenModalType] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  return (
    <>
      <DetailTab
        title="Languages"
        actionIcon={"/assets/img/ghimages/add-icon.svg"}
        actionMethod={() => setOpenModalType(ModalType.ADD)}
        children={
          <Container>
            {languages.map(({ language, proficiency }, idx) => {
              return (
                <LanguageContainer key={idx} idx={idx}>
                  <LanguageTextContainer>
                    <div>{language}</div>
                    <div>Proficiency</div>
                  </LanguageTextContainer>
                  <PillGroupContainer>
                    <PillContainer isDisabled={false}>
                      <Pill />
                      <div>
                        {proficiency === LangProficiency.BASIC ? "Basic" : ""}
                      </div>
                    </PillContainer>
                    <PillContainer
                      isDisabled={proficiency === LangProficiency.BASIC}
                    >
                      <Pill />
                      <div>
                        {proficiency === LangProficiency.INTERMEDIATE
                          ? "Intermediate"
                          : ""}
                      </div>
                    </PillContainer>
                    <PillContainer
                      isDisabled={
                        proficiency === LangProficiency.BASIC ||
                        proficiency === LangProficiency.INTERMEDIATE
                      }
                    >
                      <Pill />
                      <div>
                        {proficiency === LangProficiency.EXPERT ? "Expert" : ""}
                      </div>
                    </PillContainer>
                  </PillGroupContainer>
                  <img
                    src={"/assets/img/ghimages/edit-icon.svg"}
                    alt="edit"
                    onClick={() => {
                      setSelectedIndex(idx);
                      setOpenModalType(ModalType.EDIT);
                    }}
                  />
                </LanguageContainer>
              );
            })}
          </Container>
        }
      />
      {openModalType && (
        <AddEditLanguageModal
          closeAction={() => {
            setOpenModalType(undefined);
            setSelectedIndex(-1);
          }}
          forEdit={openModalType === ModalType.EDIT}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  );
};

export default Languages;
