import { useState, useEffect } from "react";
import YouTube from "react-youtube";
import { useLocation } from "react-router-dom";
import { Card, Row, Col, Accordion } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  GetMentorProfile,
  GetCourseByMentorId,
  GetMentorPDFbyIdService,
} from "../../services/mentor-service";
import PdfViewer from "./PdfViewer";
import Wrapper from "../../layout/wrapper";
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from "../../browser-storage";
import ReactPlayer from "react-player";
import MentorQuizList from "../placement-tools/assessment/user-assessment/available/mentor-quiz-list";

const MentorCourse = ({}) => {
  const setSeletedvideotoplay = (item) => {
    console.log("item", item);
    setPlayer("Youtube");
    setCurrentvideotoplay({
      title: item.video_title,
      videoURL: item.video_embed_code,
    });
  };
  const setPDFtoshow = (item) => {
    console.log("item", item);
    setPlayer("PDF");
    setSelectPDF({
      PDFtitle: item.pdfName,
      PDFLink: item.pdfLink,
      PDFDescription: item.pdfDescription,
    });
    setDataInLocalStorage("currentLocalPDF", item.pdfLink);
  };
  const [selectPlayer, setPlayer] = useState("Youtube");
  const [selectPDF, setSelectPDF] = useState({
    PDFtitle: "",
    PDFLink: "",
    PDFDescription: "",
  });
  const [mentorpdfdetials, setMentorPDFDetials] = useState([]);
  const [courselisting, setCourseListing] = useState([]);
  const [youtubePlaylist, setyoutubePlaylist] = useState([]);
  const [currentvidetoplay, setCurrentvideotoplay] = useState({
    title: "",
    videoURL: "",
    videoDescription: "",
    video_thumbnail: "",
  });

  const [getCurrentReferredMetor, setgetCurrentReferredMetor] = useState({});

  const [mentorprofiledetials, setMentorProfileDetials] = useState({
    userId: "",
    userEmailId: "",
    firstName: "",
    lastName: "",
    fullName: "",
    mobile: "",
    education: "",
    profession: "",
    location: "",
    profileImgUrl: "",
    dob: "",
    aboutme: "",
    linkedinlink: "",
    mentor_intro_video: "",
    sector: "",
    course_created: [],
  });

  useEffect(() => {
    const getCurrentReferredMetor = getDataFromLocalStorage(
      "getReferredMentorEmailId"
    );
    console.log("localstorage", getCurrentReferredMetor);
    // GetMentorProfileById(currentemail);
    // GetMentorPDFById(currentemail);
    // getCourseListingByMentor(currentemail);
    setgetCurrentReferredMetor(getCurrentReferredMetor);
    GetMentorProfileById(getCurrentReferredMetor);
    GetMentorPDFById(getCurrentReferredMetor);
    getCourseListingByMentor(getCurrentReferredMetor);
  }, []);

  let getCourseListingByMentor = async (currentemail) => {
    const SubmitResponse = await GetCourseByMentorId(currentemail)
      .then((resp) => {
        console.log("Mentor Course Listing", resp);
        const coureVideos = resp[0].course_videos;
        const firstTitle = coureVideos[0].video_title;
        const firstVideo = coureVideos[0].video_embed_code;
        const firstDescription = coureVideos[0].video_description;
        console.log("firstVideo", firstVideo);
        console.log("Mentor Course Videos", coureVideos);
        setCurrentvideotoplay({
          title: firstTitle,
          videoURL: firstVideo,
          videoDescription: firstDescription,
        });
        setCourseListing(coureVideos);
      })
      .catch((err) => console.log(err));
  };

  let GetMentorProfileById = async (currentemail) => {
    const SubmitResponse = await GetMentorProfile(currentemail)
      .then((resp) => {
        console.log(resp);
        let mentorresp = resp[0];
        console.log("mentorresp", mentorresp);
        let MentorCourse = mentorresp.course_created;
        console.log(MentorCourse);
        setMentorProfileDetials(mentorresp);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  let GetMentorPDFById = async (currentemail) => {
    const SubmitResponse = await GetMentorPDFbyIdService(currentemail)
      .then((resp) => {
        console.log("PDF resp", resp);
        const currentResp = resp;
        // Convert pdfOrder to numbers for proper sorting
        const sortedDetails = currentResp.sort(
          (a, b) => parseInt(a.pdfOrder) - parseInt(b.pdfOrder)
        );
        setMentorPDFDetials(sortedDetails);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  return (
    <>
      <Wrapper>
        <section className="page__title-area pt-20 pb-90">
          <div className="page__title-shape">
            <img
              className="page-title-shape-5 d-none d-sm-block"
              src="/assets/img/page-title/page-title-shape-1.png"
              alt=""
            />
            <img
              className="page-title-shape-6"
              src="/assets/img/page-title/page-title-shape-6.png"
              alt=""
            />
            <img
              className="page-title-shape-7"
              src="/assets/img/page-title/page-title-shape-4.png"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-lg-8">
                <div className="course__wrapper">
                  <div className="course__img w-img mb-30">
                    {selectPlayer == "Youtube" ? (
                      <>
                        <ReactPlayer
                          width="100%"
                          height="500px"
                          url={currentvidetoplay.videoURL}
                          controls
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                        />
                      </>
                    ) : null}

                    {selectPlayer == "PDF" ? (
                      <>
                        <div className="row">
                          <div className="text-center">
                            <PdfViewer pdfUrl={selectPDF.PDFLink} />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="page__title-content mb-25">
                    <h5 className="page__title-3">
                      {selectPlayer == "Youtube" ? (
                        <p style={{ fontSize: 18, fontWeight: 600 }}>
                          {currentvidetoplay.title}
                        </p>
                      ) : null}

                      {selectPlayer == "PDF" ? (
                        <>
                          <p style={{ fontSize: 18, fontWeight: 600 }}>
                            {selectPDF.PDFtitle}
                          </p>
                        </>
                      ) : null}
                      {/* {courseDetails.courseTitle} */}
                    </h5>
                  </div>
                  {/* <div className="course__meta-2 d-sm-flex mb-30">
                  <div className="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                      <div className="course__teacher-thumb-3 mr-15">
                        <img
                          style={{ padding: 5 }}
                          src="/assets/img/logo/GH1.png"
                          alt=""
                        />
                      </div>
                      <div className="course__teacher-info-3">
                        
                        <p>
                          <a href="#">{courseDetails.courseCreatedBy}</a>
                        </p>
                      </div>
                    </div> 

                    {/* <div className="mb-30">
                      <span className="page__title-pre">
                        {courseDetails.courseCategory}
                      </span>
                    </div> 
                  </div>*/}

                  <div className="course__tab-2 mb-45">
                    <ul className="nav nav-tabs" id="courseTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="description-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#description"
                          type="button"
                          role="tab"
                          aria-controls="description"
                          aria-selected="true"
                        >
                          {" "}
                          <i className="icon_ribbon_alt"></i>{" "}
                          <span>Description</span>{" "}
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link "
                          id="curriculum-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#curriculum"
                          type="button"
                          role="tab"
                          aria-controls="curriculum"
                          aria-selected="false"
                        >
                          {" "}
                          <i className="icon_book_alt"></i>{" "}
                          <span>Curriculum</span>{" "}
                        </button>
                      </li> */}
                    </ul>
                  </div>
                  <div className="course__tab-content mb-95">
                    <div className="tab-content" id="courseTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="description"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                      >
                        <div className="course__description">
                          {/* <h3>Course Overview</h3> */}

                          {selectPlayer == "Youtube" ? (
                            <p>{currentvidetoplay.videoDescription}</p>
                          ) : null}

                          {selectPlayer == "PDF" ? (
                            <>
                              <p>{selectPDF.PDFDescription}</p>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="curriculum"
                        role="tabpanel"
                        aria-labelledby="curriculum-tab"
                      >
                        <div className="course__curriculum">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>lessons</Accordion.Header>
                              <Accordion.Body>
                                {youtubePlaylist.map((info, i) => (
                                  <div
                                    key={i}
                                    className="course__curriculum-content d-sm-flex justify-content-between align-items-center"
                                  >
                                    <div className="course__curriculum-info">
                                      {info.course_c_icon}
                                      <h3>
                                        {" "}
                                        <span>{info.video_title}</span>
                                        {info.course_c_text_2}
                                      </h3>
                                    </div>
                                    <div className="course__curriculum-meta">
                                      <span className="time">
                                        {" "}
                                        <i className={info.clock_icon}></i>
                                        {info.minute}
                                      </span>
                                      {info.questions && (
                                        <span className="question">
                                          {info.questions}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4">
                <div className="course__sidebar pl-40 p-relative">
                  <div
                    style={{ padding: 0 }}
                    className="course__sidebar-widget-2 white-bg mb-20"
                  >
                    <div className="divscroll">
                      <div style={{ width: "100%" }} className="e-btn">
                        Course Videos
                      </div>

                      {courselisting.map((item) => {
                        return (
                          <>
                            <Card
                              onClick={() => setSeletedvideotoplay(item)}
                              className="playlistcard"
                            >
                              <Row className="singleCard">
                                <Col>
                                  <p
                                    style={{ padding: 5 }}
                                    className="singlevideoList"
                                  >
                                    <i
                                      class="fa fa-play-circle"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;
                                    {item.video_title}
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </>
                        );
                      })}
                      <div style={{ width: "100%" }} className="e-btn">
                        Course PDF
                      </div>
                      {mentorpdfdetials.map((item) => {
                        return (
                          <>
                            <Card
                              onClick={() => setPDFtoshow(item)}
                              className="playlistcard"
                            >
                              <Row className="singleCard">
                                <Col>
                                  <p
                                    style={{ padding: 5 }}
                                    className="singlevideoList"
                                  >
                                    <i
                                      class="fa fa-file"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;
                                    {item.pdfName}
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </>
                        );
                      })}
                      <MentorQuizList
                        referredMentorEmailId={getCurrentReferredMetor}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    </>
  );
};

export default MentorCourse;
