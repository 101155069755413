import { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  UserProfileEvent,
  AppConfigEvent,
} from "../../redux/types/redux-event";
import { updateUserProfile, addProfilePic } from "../../services/user-service";
import styled from "styled-components";

import {
  ModalBackground,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "./profile/common";
import {
  ButtonGroup,
  ActionButton,
  RemoveAction,
  Error,
} from "./profile/common";
import YouTube from "react-youtube";
import Pricing from "../../components/home/pricing";

const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const VideoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

interface Props {
  closeAction: VoidFunction;
  CurrentYoutubevideoCode: String;
}

const YoutubeModelHelper = ({
  closeAction,
  CurrentYoutubevideoCode,
}: Props) => {
  const dispatch = useDispatch();
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const [currentvidetoplay, setCurrentvideotoplay] = useState({
    title: "",
    videoCode: "",
  });
  const [showpricing, setShowPricing] = useState({
    title: "Premium Plans",
    show: false,
  });

  useEffect(() => {
    console.log("CurrentYoutubevideoCode", CurrentYoutubevideoCode);
    if (CurrentYoutubevideoCode == "aivideo") {
      setCurrentvideotoplay({
        title: "AI Interview Bot",
        videoCode: "VbtspjcyjsI",
      });
    }
    if (CurrentYoutubevideoCode == "TechnicalMentor") {
      setCurrentvideotoplay({
        title: "Technical Mentor",
        videoCode: "AjNq8BVKOFc",
      });
    }
    if (CurrentYoutubevideoCode == "MockInterview") {
      setCurrentvideotoplay({
        title: "Mock Interview",
        videoCode: "wfKMJMqITwg",
      });
    }
    if (CurrentYoutubevideoCode == "InterviewTips") {
      setCurrentvideotoplay({
        title: "Interview Tips",
        videoCode: "L6MlSveKvc0",
      });
    }
    if (CurrentYoutubevideoCode == "Resume") {
      setCurrentvideotoplay({ title: "Resume", videoCode: "KdSSN5q3Vls" });
    }
    if (CurrentYoutubevideoCode == "Confidence") {
      setCurrentvideotoplay({
        title: "Confidence Builder",
        videoCode: "BE0BOMXR7hY",
      });
    }
    if (CurrentYoutubevideoCode == "Aptitude") {
      setCurrentvideotoplay({ title: "Aptitude", videoCode: "B6z11ed2c8k" });
    }
    if (CurrentYoutubevideoCode == "Premium") {
      setShowPricing({
        title: "Premium Plans",
        show: true,
      });
    }
  }, []);

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  let _onReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <ModalBackground>
      <Modal>
        <ModalHeader>
          {currentvidetoplay.title}
          <img
            src={"/assets/img/ghimages/close-icon.svg"}
            alt="X"
            onClick={closeAction}
          />
        </ModalHeader>
        <ModalBody>
          {showpricing.show == false ? (
            <>
              <div className="text-center">
                <div className="desktop">
                  <YouTube
                    className="cusmobilevideo"
                    videoId={currentvidetoplay.videoCode}
                    opts={opts}
                    onReady={_onReady}
                  />
                </div>

                <div className="mobile">
                  <VideoBlock className="">
                    <Video
                      src={`https://www.youtube.com/embed/${currentvidetoplay.videoCode}`}
                    />
                  </VideoBlock>
                </div>
              </div>
            </>
          ) : (
            <div>
              <Pricing />
            </div>
          )}
        </ModalBody>
      </Modal>
    </ModalBackground>
  );
};
export default memo(YoutubeModelHelper);
