const MonthArray = [
  { name: "January", value: "Jan" },
  { name: "February", value: "Feb" },
  { name: "March", value: "Mar" },
  { name: "April", value: "Apr" },
  { name: "May", value: "May" },
  { name: "June", value: "Jun" },
  { name: "July", value: "Jul" },
  { name: "August", value: "Aug" },
  { name: "September", value: "Sep" },
  { name: "October", value: "Oct" },
  { name: "November", value: "Nov" },
  { name: "December", value: "Dec" },
];

const MonthNumberArray = [
  { name: "01", value: "01" },
  { name: "02", value: "02" },
  { name: "03", value: "03" },
  { name: "04", value: "04" },
  { name: "05", value: "05" },
  { name: "06", value: "06" },
  { name: "07", value: "07" },
  { name: "08", value: "08" },
  { name: "09", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
];

const DayArray = [
  { name: "01", value: "01" },
  { name: "02", value: "02" },
  { name: "03", value: "03" },
  { name: "04", value: "04" },
  { name: "05", value: "05" },
  { name: "06", value: "06" },
  { name: "07", value: "07" },
  { name: "08", value: "08" },
  { name: "09", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
  { name: "13", value: "13" },
  { name: "14", value: "14" },
  { name: "15", value: "15" },
  { name: "16", value: "16" },
  { name: "17", value: "17" },
  { name: "18", value: "18" },
  { name: "19", value: "19" },
  { name: "20", value: "20" },
  { name: "21", value: "21" },
  { name: "22", value: "22" },
  { name: "23", value: "23" },
  { name: "24", value: "24" },
  { name: "25", value: "25" },
  { name: "26", value: "26" },
  { name: "27", value: "27" },
  { name: "28", value: "28" },
  { name: "29", value: "29" },
  { name: "30", value: "30" },
  { name: "31", value: "31" },
];

const YearArray = [
  "2025",
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2001",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
];

const Circle = ({ filled }) => {
  const color = filled ? "#28ae71" : "";

  const circleStyle = {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    border: "2px solid #28ae71",
    backgroundColor: color,
    margin: "5px",
  };

  return <div style={circleStyle}></div>;
};
const CirlceRow = ({ value }) => {
  const circles = Array.from(Array(5), (_, index) => index < value);

  return (
    <div>
      <div class="d-flex flex-row bd-highlight mb-3">
        {circles.map((filled, index) => (
          <div class="bd-highlight">
            <Circle key={index} filled={filled} />
          </div>
        ))}
      </div>
    </div>
  );
};
export { MonthArray, DayArray, MonthNumberArray, YearArray, Circle, CirlceRow };
