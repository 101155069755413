import { useFormik } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";
import ErrorMsg from "./error-msg";
import { loginSchema } from "./validation-schema";
import { login } from "../../../services/user-service";
import { setDataInLocalStorage } from "../../../browser-storage/local-storage";
import { toast } from "react-toastify";
const LoginForm = () => {
  //set auth
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  console.log("check from value", from);

  console.log(location, " useLocation Hook");

  const SingleCourse = location.state?.data;
  console.log("SingleCourse", SingleCourse);

  // contact form
  const handleOnSubmit = async (values, { resetForm }) => {
    //alert(`${values.email + "\n" + values.password}`);
    const loginResponse = await login(values.email, values.password)
      .then((resp) => {
        console.log("resp", resp);
        const username = resp.userName;
        const pwd = resp.password;
        const role = resp.role;
        const accesstoken = resp.token;
        console.log(username, pwd, role, accesstoken);
        //setAuth(resp)
        localStorage.setItem("user", JSON.stringify(resp));
        setDataInLocalStorage("token", accesstoken);
        setDataInLocalStorage("user-details", resp);
        console.log();
        //used in the tutorial,but seems not fisible in my case
        // navigate(from,{replace:true})
        if (role == "STUDENT") {
          navigate("/student-dashboard", {
            state: { data: location.state?.data },
          });
        }
        if (role == "EMPLOYER") {
          console.log("employerr");
          navigate("/recuiter-dashboard", { replace: true });
        }
        if (role == "MENTOR") {
          console.log("mentor");
          navigate("/mentor-dashboard", { replace: true });
        }
        if (role == "ADMIN") {
          console.log("ADMIN");
          navigate("/admin-dashboard", { replace: true });
        }
        if (role == "CAMPUS") {
          navigate("/placement-officer-dashboard", { replace: true });
        }
      })
      .catch((err) => {
        toast.error(`Error : Please check your username and password `, {
          position: "top-left",
        });
      });
    console.log("loginResponse", loginResponse);
    resetForm();
  };
  // use formik
  const { handleChange, handleSubmit, handleBlur, errors, values, touched } =
    useFormik({
      initialValues: { email: "", password: "" },
      validationSchema: loginSchema,
      onSubmit: handleOnSubmit,
    });
  return (
    <form onSubmit={handleSubmit}>
      <div className="sign__input-wrapper mb-25">
        <h5>Username</h5>
        <div className="sign__input">
          <input
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="e-mail address"
            name="email"
            autocapitalize="none"
          />
          <i className="fal fa-envelope"></i>
        </div>
        {touched.email && <ErrorMsg error={errors.email} />}
      </div>
      <div className="sign__input-wrapper mb-10">
        <h5>Password</h5>
        <div className="sign__input">
          <input
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            name="password"
            type="password"
            placeholder="Password"
            autocapitalize="none"
          />
          <i className="fal fa-lock"></i>
        </div>
        {touched.password && <ErrorMsg error={errors.password} />}
      </div>
      <div className="sign__action d-sm-flex justify-content-between mb-30">
        <div className="sign__agree d-flex align-items-center">
          <input className="m-check-input" type="checkbox" id="m-agree" />
          <label className="m-check-label" htmlFor="m-agree">
            Keep me signed in
          </label>
        </div>
        <div className="sign__forgot">
          <Link to="/password-reset">Forgot your password?</Link>
        </div>
      </div>
      <button className="e-btn  w-100">
        {" "}
        <span></span> Sign In
      </button>
      <div className="sign__new text-center mt-20">
        <p>
          New to GreenHorn? <Link to="/student-signup">Sign Up</Link>
        </p>
      </div>
    </form>
  );
};

export default LoginForm;
