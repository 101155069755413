import { Link } from "react-router-dom";

const pricing_data = [
  {
    id: 1,
    pricing_plan: [
      {
        price_title: "Crack your dream interview",
        price_subtitle:
          "Learn everything about cracking your next big career interview",
        price: 9999,
        navige_link: "/custom-payment-page",
        price_features: [
          "Communication and confidence",
          "Interview fundamentals",
          "ATS resume",
          "Elevator pitch",
          "LinkedIn",
          "Frequently asked questions",
          "AI interview",
        ],
      },
    ],
  },
];

const WhitePricingSingle = () => {
  return (
    <>
      <section className="price__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 offset-xxl-4">
              <div
                style={{ paddingTop: 30 }}
                className="section__title-wrapper mb-60 text-center"
              >
                <h2 className="section__title">
                  Our{" "}
                  <span className="yellow-bg yellow-bg-big">
                    Package
                    <img src="/assets/img/shape/yellow-bg.png" alt="" />
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="price__tab-content">
                <div className="tab-content" id="nav-tabContent">
                  <div className="row">
                    <div className="col-md-7">
                      <img src="/assets/img/ghimages/1.jpg" />
                    </div>
                    <div className="col-md-4">
                      {pricing_data.map((item, index) => (
                        <div className="row ">
                          {item.pricing_plan.map((price, index) => (
                            <div key={index} className="">
                              <div
                                className="price__item grey-bg mb-30 p-relative"
                                style={{ padding: "20px" }}
                              >
                                <div className="price__head">
                                  <h4>{price.price_title}</h4>
                                </div>
                                <div className="price__features mb-40">
                                  <ul>
                                    {price.price_features.map(
                                      (feature, index) => (
                                        <li key={index}>
                                          {!feature == "" ? (
                                            <>
                                              <i className="far fa-check"></i>
                                              {feature}
                                            </>
                                          ) : null}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                                <p>
                                  BONUS: Get access to 1000+ Sample Answers that
                                  will fit any Job Role.
                                </p>
                                <Link
                                  to="/main-payment-page"
                                  state={{ data: price }}
                                  className={`e-btn "e-btn-4" : "e-btn-border"
                                }`}
                                >
                                  Get Started
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhitePricingSingle;
