import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import { useState } from "react";
import { toast } from "react-toastify";
import { signupv2 } from "../../services/user-service";
import { setDataInLocalStorage } from "../../browser-storage";
import { LocalStorageConstants } from "../../services/localstorageconstants";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../services/config";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import StudentSignUpFormWithValidation from "./StudentSignUpFormWithValidation";
const StudentSignUp = () => {
  return (
    <Wrapper>
      <SEO pageTitle={""} />
      <section className="signup__area po-rel-z1 pt-100 pb-145">
        <div className="sign__shape">
          <img className="man-1" src="/assets/img/icon/sign/man-3.png" alt="" />
          <img
            className="man-2 man-22"
            src="/assets/img/icon/sign/man-2.png"
            alt=""
          />
          <img
            className="circle"
            src="/assets/img/icon/sign/circle.png"
            alt=""
          />
          <img
            className="zigzag"
            src="/assets/img/icon/sign/zigzag.png"
            alt=""
          />
          <img className="dot" src="/assets/img/icon/sign/dot.png" alt="" />
          <img className="bg" src="/assets/img/icon/sign/sign-up.png" alt="" />
          <img
            className="flower"
            src="/assets/img/icon/sign/flower.png"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
              <div className="section__title-wrapper text-center mb-55">
                <h2 className="section__title">
                  Create a free <br /> Account
                </h2>
                {/* <p>I'm a subhead that goes with a story.</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
              <div className="sign__wrapper white-bg">
                <div className="sign__header mb-35"></div>

                <StudentSignUpFormWithValidation />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default StudentSignUp;
