const mentor_menu_data = [
  {
    id: 8,
    title: "Create Course",
    link: "/mentor-manage-dynamic-tabs",
  },
  // {
  //   id: 7,
  //   title: "Add PDF",
  //   link: "/mentor-pdf-manager",
  // },
  // {
  //   id: 3,
  //   title: "Add Video",
  //   link: "/manage-course",
  // },
  {
    id: 4,
    title: "My Students",
    link: "/student-collection",
  },
  // {
  //   id: 9,
  //   title: "Student Access",
  //   link: "/mentor-manage-student-dynamic-permissions",
  // },
  {
    id: 2,
    title: "Profile ",
    link: "/mentor-profile",
  },

  {
    id: 5,
    title: "Coupons",
    link: "/mentor-coupons-listing",
  },
  // {
  //   id: 6,
  //   title: "Coupons Used",
  //   link: "/mentor-coupons-usedby-students",
  // },
];

export default mentor_menu_data;
