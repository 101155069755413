import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { useEffect, useState, useRef } from "react";
import {
  GetCourseByMentorId,
  AddMentorCourse,
  EditCourseListing,
  DeleteCourseListing,
  GetCourseByKeyword,
  DeleteCourseListingbyKeyword,
} from "../../services/mentor-service";
import { getDataFromLocalStorage } from "../../browser-storage";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import * as DOMPurify from "dompurify";
import config from "../../services/config";

import { v4 as uuid } from "uuid";

import {
  AddMentorDynamicTabs,
  getDynamicTabsDatabyMentorId,
  UpdateDynamicCourseListing,
  UpdateDynamicTabsListing,
} from "../../services/dynamictabs-service";
import {
  AddMentorPDFEntry,
  AddMentorProfile,
  DeletePDFListing,
  GetMentorPDFbyIdService,
  GetMentorProfile,
  UpdateMentorProfileById,
  UpdateMentorPDFEntry,
} from "../../services/mentor-service";
import MentorSliderMenu from "./mentorslidermenu";

import Dropzone from "react-dropzone";
import Modal from "react-modal";
import { toBase64 } from "../../helpers/index";
import styled from "styled-components";
import { UploadMentorPDF } from "../../services/user-service";
const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 232px;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  gap: 8px;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: rgba(61, 41, 36, 0.8);
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: rgba(61, 41, 36, 0.8);
  }
  img {
    width: 220px;
    height: 220px;
    border-radius: 5px;
  }
`;
const MentorManageDynamicTabs = () => {
  const navigate = useNavigate();
  const [dynamictabslisting, setDynamicTabListing] = useState([]);
  const [courseListing, setCourseListing] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [coursecreated, setcoursecreated] = useState("false");
  const MentorDetials = getDataFromLocalStorage("user-details");
  useEffect(() => {
    getDynamicTabsListingByMentorId();
  }, []);

  let getDynamicTabsListingByMentorId = async () => {
    console.log(MentorDetials);
    // const getmentorname = MentorDetials.fullName;
    // const getmentorusername = MentorDetials.userName;
    // const profileImgUrl = MentorDetials.profileImgUrl;
    const uniquecoursecode = uuid();
    const currentemail = MentorDetials.userName;
    console.log("uniquecoursecode", uniquecoursecode);
    console.log("currentemail", currentemail);
    setDynamicTabsDetails({
      dynamicTabForMentorId: currentemail,
    });

    const SubmitResponse = await getDynamicTabsDatabyMentorId(currentemail)
      .then((resp) => {
        console.log(resp);
        const respreverse = resp.reverse();
        const getArr = resp[0].dynamicTabsArray;

        setCourseListing(getArr);
        setDynamicTabListing(respreverse);
        if (resp.length > 0) {
          setcoursecreated("true");

          // Perform additional actions if needed
        }

        //GetMentorProfileById(currentemail);
      })
      .catch((err) => console.log(err));
  };

  const [DynamicTabsDetails, setDynamicTabsDetails] = useState({
    dynamicTabForMentorId: "",
    dynamicTabsArray: [],
  });
  let onChange = (evt) => {
    const value = evt.target.value;
    setDynamicTabsDetails({
      ...DynamicTabsDetails,
      [evt.target.name]: value,
    });
    console.log(DynamicTabsDetails);
  };
  useEffect(() => {
    getDynamicTabsListingByMentorId();
  }, []);

  let EditHandler = (item) => {
    console.log(item);
    console.log(item.dynamicTabForMentorId);
    setEditMode(true);
    setDynamicTabsDetails({
      dynamicTabForMentorId: item.dynamicTabForMentorId,
      dynamicTabsArray: item.dynamicTabsArray,
    });
    setDynamicTabs(item.dynamicTabsArray);
  };

  //   let DeleteHandler = (item) => {
  //     console.log(item);
  //     const dynamicTabForMentorId = item.course_code;
  //     DeleteCourseListing(course_code).then((resp) => {
  //       console.log(resp);
  //       getCourseListingByMentor();
  //     });
  //   };

  const [DynamicTabs, setDynamicTabs] = useState([
    {
      dynamicTabId: "",
      dynamicTabTitle: "",
      dynamicTabCondition: "",
      dynamicTabOrder: "",
    },
  ]);
  useEffect(() => {
    const initialDynamicTab = {
      dynamicTabId: "",
      dynamicTabTitle: "",
      dynamicTabCondition: generateRandomId(),
      dynamicTabOrder: "",
    };

    setDynamicTabs([initialDynamicTab]);
  }, []);

  let DynamicTabshandleChange = (i, e) => {
    let value = [...DynamicTabs];
    value[i][e.target.name] = e.target.value;
    DynamicTabs[i][e.target.name] = value[i][e.target.name];
    setDynamicTabs(DynamicTabs);
    setDynamicTabs([...DynamicTabs]);
    console.log(DynamicTabs);
  };

  const generateRandomId = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";
    for (let i = 0; i < 8; i++) {
      randomId += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    let finalcode = MentorDetials.userName + "_" + randomId;
    return finalcode;
  };

  let addDynamicTabsFields = () => {
    setDynamicTabs([
      ...DynamicTabs,
      {
        dynamicTabId: "",
        dynamicTabTitle: "",
        dynamicTabCondition: generateRandomId(),
        dynamicTabOrder: "",
      },
    ]);
  };

  let removeDynamicTabsFields = (i) => {
    let value = [...DynamicTabs];
    value.splice(i, 1);
    setDynamicTabs(value);
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  const SubmitDynamicTabsDetials = async () => {
    try {
      if (!isSubmitted) {
        console.log("DynamicTabs", DynamicTabs);
        const DynamicTabsDetails = { DynamicTabs: DynamicTabs };

        const submitResponse = await AddMentorDynamicTabs(DynamicTabsDetails);

        if (submitResponse) {
          setIsSubmitted(true);
          toast.success("Submit Successfully", { position: "top-left" });
          // Reload the page
          //window.location.reload();
          navigate("/mentor-manage-dynamic-tabs", { replace: true });
        } else {
          throw new Error("Submit failed");
        }
      }
    } catch (error) {
      toast.error(`Error ${error}`, { position: "top-left" });
    }
  };

  let SubmitEditDynamicTabsDetails = async () => {
    DynamicTabsDetails.dynamicTabsArray = DynamicTabs;
    console.log(DynamicTabsDetails);
    const SubmitResponse = await UpdateDynamicTabsListing(DynamicTabsDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        window.location.reload();
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  //Add Video Part
  const [CourseDetails, setCourseDetails] = useState({
    course_code: "",
    course_keyword: "",
    course_title: "",
    course_createdby_mentor: "",
    course_mentor_profileImgUrl: "",
    course_category: "",
    course_duration: "",
    course_description: "",
    course_short_description: "",
    course_price: "Free",
    course_banner_link: "",
    course_student_enrolled: "",
    course_liked: "",
    course_shared: "",
    course_created_by_our_mentor: true,
    course_created_date: "",
    userEmailId: "",
    course_videos_count: "",
    course_videos: [],
  });

  const [CourseVideosDetials, setCourseVideosDetials] = useState([
    {
      video_title: "",
      video_embed_code: "",
      video_description: "",
      video_duration: "",
    },
  ]);

  let CourseVideoshandleChange = (i, e) => {
    let newCourseVideos = [...CourseVideosDetials];
    newCourseVideos[i][e.target.name] = e.target.value;
    CourseVideosDetials[i][e.target.name] = newCourseVideos[i][e.target.name];
    setCourseVideosDetials(CourseVideosDetials);
    setCourseVideosDetials([...CourseVideosDetials]);
    console.log(CourseVideosDetials);
  };

  let addCourseVideosFormFields = () => {
    setCourseVideosDetials([
      ...CourseVideosDetials,
      {
        video_title: "",
        video_embed_code: "",
        video_description: "",
        video_duration: "",
      },
    ]);
  };

  let clearDynamicTabsFields = () => {
    setDynamicTabs([]);
  };

  let ClearAllCourseVideosFormFields = () => {
    setCourseVideosDetials([]);
  };

  let removeCourseVideosFormFields = (i) => {
    let newCourseVideosValues = [...CourseVideosDetials];
    newCourseVideosValues.splice(i, 1);
    setCourseVideosDetials(newCourseVideosValues);
  };

  const [
    showVideoInputWhenCourseSelected,
    setshowVideoInputWhenCourseSelected,
  ] = useState("false");

  const AddCourseIdtoVideo = async (item) => {
    // setCourseDetails({});
    // setCourseDetails({});
    // setCourseVideosDetials([]);
    // setCourseVideosDetials([]);
    setshowVideoInputWhenCourseSelected("true");
    console.log("AddCourseIdtoVideo", item);
    const resp = await GetCourseByKeyword(item.dynamicTabCondition).then(
      (resp) => {
        let current_video;

        if (resp[0] && resp[0].course_videos) {
          current_video = resp[0].course_videos;
          console.log("current_videos", current_video);
          setCourseVideosDetials(current_video);
          setCourseDetails({
            course_keyword: item.dynamicTabCondition,
            course_title: item.dynamicTabTitle,
          });
        } else {
          setCourseDetails({
            course_keyword: item.dynamicTabCondition,
            course_title: item.dynamicTabTitle,
          });
        }
      }
    );
  };

  //Submit Video Course
  let SubmitCourseDetials = async (e) => {
    console.log("Course Details", CourseDetails);
    // console.log('Module Details', ModuleDetials)
    // console.log('ModuleVideosDetials', ModuleVideosDetials)
    //console.log("CourseVideosDetials", CourseVideosDetials);
    if (CourseDetails.course_keyword) {
      CourseDetails.course_videos = CourseVideosDetials;
      console.log(CourseDetails);

      const SubmitResponse = await AddMentorCourse(CourseDetails)
        .then((resp) => {
          toast.success(`Submit Successfully `, {
            position: "top-left",
          });
          //window.location.reload();
        })
        .catch((err) =>
          toast.error(`Error ${err} `, {
            position: "top-left",
          })
        );
    } else {
      toast.error(`Please click on Add video button to link videos `, {
        position: "top-left",
      });
    }
  };

  const deleteVideo = async (item) => {
    console.log("item", item);
    const current_keyword = item.dynamicTabCondition;
    const SubmitResponse = await DeleteCourseListingbyKeyword(current_keyword)
      .then((resp) => {
        console.log(resp);
        toast.success(`Deleted Successfully `, {
          position: "top-left",
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  const [showPDFInputWhenCourseSelected, setshowPDFInputWhenCourseSelected] =
    useState("false");
  //PDF Part
  const [mentorpdfdetials, setMentorPDFDetials] = useState({
    userEmailId: "",
    userId: "",
    pdfEntryUniqueCode: "",
    MentorEmailId: "",
    pdfName: "",
    pdfDescription: "",
    pdfOrder: "",
    pdfLink: "",
    pdf_linked_to_course: "",
    CourseNameLinked: "",
  });
  const [mentorpdfdetialsArray, setMentorPDFDetialsArray] = useState([]);

  const AddCourseIdtoPDF = async (item) => {
    setshowPDFInputWhenCourseSelected("true");
    console.log("AddCourseIdtoPDF", item);

    setMentorPDFDetials({
      ...mentorpdfdetials,
      pdf_linked_to_course: item.dynamicTabCondition,
      CourseNameLinked: item.dynamicTabTitle,
    });
  };

  const UpdatePdf = (item) => {
    console.log(item);
    setedit("true");
    setMentorPDFDetials({
      ...mentorpdfdetials,
      pdfName: item.pdfName,
      pdfDescription: item.pdfDescription,
      pdfOrder: item.pdfOrder,
      pdf_linked_to_course: item.pdf_linked_to_course,
      pdfEntryUniqueCode: item.pdfEntryUniqueCode,
      CourseNameLinked: item.CourseNameLinked,
    });
  };

  const userDetials = getDataFromLocalStorage("user-details");

  useEffect(() => {
    console.log(userDetials);

    const currentemail = userDetials.userName;
    setMentorPDFDetials({ userEmailId: currentemail });
    GetMentorPDFById(currentemail);
  }, []);

  //Uploader Code
  //const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showErrors, setShowErrors] = useState(false);

  const [isedit, setedit] = useState("false");

  //Using Old login
  const setupPDF = async (file) => {
    console.log("file", file);
    console.log(file[0]);
    const base64 = await toBase64(file[0]).catch(() =>
      toast.error(`Unable to Upload Image`, {
        position: "top-left",
      })
    );
    console.log("base64", base64);
    if (base64) {
      setUploadedFile({ uploadedFile: file[0], fileUrl: base64 });
    }
    console.log("uploadedFile", uploadedFile.fileUrl);
    setShowErrors(false);
  };

  const uploadPDF = () => {
    if (!uploadedFile.uploadedFile || showErrors) {
      return;
    }

    UploadMentorPDF(uploadedFile.uploadedFile)
      .then((data) => {
        console.log(data);
        toast.success(`Profile picture updated successfully`, {
          position: "top-left",
        });
        const resp = data.pdfLink;
        setMentorPDFDetials({
          ...mentorpdfdetials,
          pdfLink: resp,
        });
      })
      .catch(() =>
        toast.error(`Unable to update your profile picture`, {
          position: "top-left",
        })
      );
  };

  let ProfileDetailsonChange = (evt) => {
    const value = evt.target.value;
    setMentorPDFDetials({
      ...mentorpdfdetials,
      [evt.target.name]: value,
    });
    console.log(mentorpdfdetials);
  };

  let SubmitMentorPDFEntry = async () => {
    //logic to get uploaded pdf and mix ad upload
    setMentorPDFDetials({
      ...mentorpdfdetials,
      pdfLink:
        "https://s3.ap-south-1.amazonaws.com/qa.mygreenhorn.com/assets/img/ghimages/blankprofile.png",
    });
    console.log(mentorpdfdetials);
    //final entry with pdf link
    const SubmitResponse = await AddMentorPDFEntry(mentorpdfdetials)
      .then((resp) => {
        console.log(resp);
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        //window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  //Fetch
  let GetMentorPDFById = async (currentemail) => {
    const SubmitResponse = await GetMentorPDFbyIdService(currentemail)
      .then((resp) => {
        console.log(resp);
        const currentResp = resp;
        setMentorPDFDetialsArray(currentResp);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  const deletePDF = async (item) => {
    console.log("item", item);
    const SubmitResponse = await DeletePDFListing(item)
      .then((resp) => {
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  const SubmitUpdateMentorPDFEntry = async () => {
    const SubmitResponse = await UpdateMentorPDFEntry(mentorpdfdetials)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        GetMentorPDFById(userDetials.userName);
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };
  const switchtoaddmode = () => {
    setedit("false");
    setMentorPDFDetials({
      pdfName: "",
      pdfDescription: "",
      pdfOrder: "",
      pdf_linked_to_course: "",
    });
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Manage Courses"} />

      <Container>
        <Card className="carddesign marginTop5">
          <Card.Body>
            <Card.Text>
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Add / Manage Course</Accordion.Header>
                  <Accordion.Body>
                    <Row style={{ marginTop: "50px" }}>
                      {dynamictabslisting.map((item) => (
                        <Card className="carddesign" key={item.uniqueKey}>
                          <Container className="jobcontainer">
                            <Row style={{ padding: 15 }}>
                              <Col sm={8}>
                                <p className="jobtitle">
                                  <b className="boldtitle">
                                    Courses for Mentor Id:{" "}
                                    {item.dynamicTabForMentorId}
                                  </b>
                                </p>
                              </Col>
                              <Col>
                                <button
                                  className="e-btn"
                                  onClick={() => EditHandler(item)}
                                >
                                  Edit
                                </button>
                              </Col>
                            </Row>
                          </Container>
                        </Card>
                      ))}
                    </Row>
                    <Card className="MainCard">
                      {editMode == false ? (
                        <>
                          <Col>
                            <div className="section__title-wrapper mb-10 ml-20 mt-10">
                              <h2 className="section__title">
                                <span className="yellow-bg">
                                  Manage{" "}
                                  <img
                                    src="/assets/img/shape/yellow-bg-2.png"
                                    alt=""
                                  />
                                </span>
                                Course
                              </h2>
                            </div>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Container>
                            <Row>
                              <Col sm={8}>
                                <div className="section__title-wrapper mb-45">
                                  <h2 className="section__title">
                                    Edit{" "}
                                    <span className="yellow-bg">
                                      Course{" "}
                                      <img
                                        src="/assets/img/shape/yellow-bg-2.png"
                                        alt=""
                                      />
                                    </span>
                                    Listing
                                  </h2>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </>
                      )}

                      <Container>
                        <div className="MobTextStyle">
                          <input
                            placeholder="Mentor Email Id"
                            className="form-control marginTop5"
                            type="text"
                            name="dynamicTabForMentorId"
                            value={DynamicTabsDetails.dynamicTabForMentorId}
                            disabled
                          />
                        </div>
                        <Card className="carddesign marginTop5">
                          <Card.Body>
                            <Card.Title>Courses</Card.Title>
                            <Card.Text>
                              {editMode == true || coursecreated == "false" ? (
                                <>
                                  {DynamicTabs.map((element, index) => (
                                    <div className="form-inline" key={index}>
                                      <input
                                        placeholder="Course Name"
                                        className="form-control"
                                        type="text"
                                        name="dynamicTabTitle"
                                        value={element.dynamicTabTitle}
                                        onChange={(e) =>
                                          DynamicTabshandleChange(index, e)
                                        }
                                      />
                                      <input
                                        placeholder="Unique Keyword for Course"
                                        className="form-control"
                                        type="text"
                                        name="dynamicTabCondition"
                                        value={element.dynamicTabCondition}
                                        onChange={(e) =>
                                          DynamicTabshandleChange(index, e)
                                        }
                                        disabled
                                        style={{ display: "none" }}
                                      />

                                      {index ? (
                                        <button
                                          type="button"
                                          style={{ background: "red" }}
                                          className="btn btn-primary button remove"
                                          onClick={() =>
                                            removeDynamicTabsFields(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      <hr />
                                    </div>
                                  ))}
                                  {/* <div className="button-section">
                                    <button
                                      className="e-btn"
                                      type="button"
                                      onClick={() => addDynamicTabsFields()}
                                    >
                                      Add
                                    </button>
                                  </div> */}
                                  <div class="d-flex flex-row bd-highlight mb-3">
                                    <div class="p-2 bd-highlight">
                                      <div className="button-section">
                                        <button
                                          className="e-btn"
                                          type="button"
                                          onClick={() => addDynamicTabsFields()}
                                        >
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                    <div class="p-2 bd-highlight">
                                      <div className="button-section">
                                        <button
                                          className="e-btn"
                                          type="button"
                                          style={{ background: "red" }}
                                          onClick={() =>
                                            clearDynamicTabsFields()
                                          }
                                        >
                                          Clear all
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Container>
                      {editMode == false ? (
                        <>
                          {coursecreated == "false" ? (
                            <>
                              <button
                                className="e-btn"
                                onClick={SubmitDynamicTabsDetials}
                              >
                                Submit
                              </button>
                            </>
                          ) : (
                            <>
                              <p className="ml-20 mt-10 mb-10">
                                You have already created course, please use edit
                                to create more courses
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <button
                          className="e-btn marginTop5"
                          onClick={SubmitEditDynamicTabsDetails}
                        >
                          Submit
                        </button>
                      )}
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
      <Card style={{ margin: 15 }} className="carddesign marginTop5">
        <Card.Body>
          <Card.Text>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Course List</Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Index</th>
                        <th style={{ width: "50%" }}>Course Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseListing.map((element, index) => {
                        return (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{element.dynamicTabTitle}</td>
                              <td>
                                <div class="d-flex flex-row bd-highlight mb-3">
                                  <div class="p-2 bd-highlight">
                                    <button
                                      className="e-btn"
                                      onClick={() =>
                                        AddCourseIdtoVideo(element)
                                      }
                                    >
                                      Add Video
                                    </button>
                                  </div>
                                  <div class="p-2 bd-highlight">
                                    <button
                                      className="e-btn"
                                      style={{ background: "red" }}
                                      onClick={() => deleteVideo(element)}
                                    >
                                      Delete video
                                    </button>
                                  </div>
                                  <div class="p-2 bd-highlight">
                                    <button
                                      className="e-btn"
                                      onClick={() => AddCourseIdtoPDF(element)}
                                    >
                                      Add PDF
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Text>
        </Card.Body>
      </Card>
      <Card style={{ margin: 15 }} className="carddesign marginTop5">
        <Card.Body>
          <Card.Text>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Add Video</Accordion.Header>
                <Accordion.Body>
                  {showVideoInputWhenCourseSelected == "true" ? (
                    <Row>
                      <Col sm={9}>
                        {CourseVideosDetials.map((element, index) => (
                          <div className="form-inline row mt-15" key={index}>
                            <div className="col-md-11">
                              <input
                                placeholder="Video Title"
                                className="form-control"
                                type="text"
                                name="video_title"
                                value={element.video_title}
                                onChange={(e) =>
                                  CourseVideoshandleChange(index, e)
                                }
                              />
                              <input
                                placeholder="Add video link"
                                className="form-control"
                                type="text"
                                name="video_embed_code"
                                value={element.video_embed_code}
                                onChange={(e) =>
                                  CourseVideoshandleChange(index, e)
                                }
                              />
                            </div>
                            <div className="col-md-1">
                              {index ? (
                                <button
                                  type="button"
                                  className="btn btn-primary button remove"
                                  style={{ background: "red" }}
                                  onClick={() =>
                                    removeCourseVideosFormFields(index)
                                  }
                                >
                                  Remove
                                </button>
                              ) : null}
                            </div>
                          </div>
                        ))}
                      </Col>
                      <Col sm={3}>
                        <div style={{ float: "right" }}>
                          <button
                            className="e-btn"
                            type="button"
                            onClick={() => addCourseVideosFormFields()}
                          >
                            Add new video
                          </button>
                          {/* <button
                            className="e-btn mt-5"
                            type="button"
                            style={{ background: "red" }}
                            onClick={() => ClearAllCourseVideosFormFields()}
                          >
                            Clear all
                          </button> */}
                        </div>
                        <div class="d-flex flex-row bd-highlight mb-3">
                          <div class="p-2 bd-highlight"></div>
                          <div class="p-2 bd-highlight"></div>
                          <div class="p-2 bd-highlight"></div>
                        </div>
                      </Col>
                      <button className="e-btn" onClick={SubmitCourseDetials}>
                        Submit
                      </button>
                    </Row>
                  ) : (
                    <>
                      <p>Please select any course to add videos</p>
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Text>
        </Card.Body>
      </Card>
      <Card style={{ margin: 15 }} className="carddesign marginTop5">
        <Card.Body>
          <Card.Text>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Add PDF</Accordion.Header>
                <Accordion.Body>
                  <div className="section__title-wrapper mb-10 ml-20 mt-10">
                    <h2 className="section__title">
                      <span className="yellow-bg">
                        Course{" "}
                        <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                      </span>
                      PDF list
                    </h2>
                  </div>

                  <hr />
                  <table
                    border={0}
                    style={{
                      tableLayout: "fixed",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <colgroup>
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "5%" }} />
                      <col style={{ width: "10%" }} />
                    </colgroup>
                    <tr>
                      <td style={{ padding: "5px", border: "1px solid gray" }}>
                        Course Name
                      </td>
                      <td style={{ padding: "5px", border: "1px solid gray" }}>
                        Title
                      </td>
                      <td style={{ padding: "5px", border: "1px solid gray" }}>
                        Description
                      </td>
                      <td style={{ padding: "5px", border: "1px solid gray" }}>
                        Order
                      </td>
                      <td style={{ padding: "5px", border: "1px solid gray" }}>
                        Action
                      </td>
                    </tr>

                    {mentorpdfdetialsArray.map((item) => {
                      return (
                        <>
                          <tr>
                            <td
                              style={{
                                padding: "5px",
                                border: "1px solid gray",
                                maxWidth: "100px",
                                wordWrap: "break-word",
                              }}
                            >
                              {item.CourseNameLinked}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                border: "1px solid gray",
                                maxWidth: "100px",
                                wordWrap: "break-word",
                              }}
                            >
                              {item.pdfName}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                border: "1px solid gray",
                                maxWidth: "100px",
                                wordWrap: "break-word",
                              }}
                            >
                              {item.pdfDescription}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                border: "1px solid gray",
                              }}
                            >
                              {item.pdfOrder}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                border: "1px solid gray",
                              }}
                            >
                              <button
                                className="e-btn"
                                onClick={() => UpdatePdf(item)}
                              >
                                Edit
                              </button>
                              <button
                                className="e-btn mt-5"
                                onClick={() => deletePDF(item)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                  <hr />
                  {showPDFInputWhenCourseSelected == "true" ? (
                    <>
                      <div className="MobTextStyle">
                        <div className="container">
                          <div style={{ marginTop: 20 }} className="row">
                            <div className="col-md-3">
                              {uploadedFile !== null ? (
                                <>
                                  <img src="/assets/img/ghimages/uploadimg.png" />
                                  <p
                                    style={{
                                      color: "green",
                                      fontSize: 18,
                                      fontWeight: 900,
                                    }}
                                  >
                                    PDF ready to Upload
                                  </p>
                                </>
                              ) : (
                                <Dropzone
                                  accept=""
                                  onDrop={(file) => setupPDF(file)}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <DragDropContainer>
                                        <>
                                          <p>Drag & Drop</p>
                                          <p>Or</p>
                                          <button className="e-btn">
                                            Upload
                                          </button>
                                        </>
                                      </DragDropContainer>
                                    </div>
                                  )}
                                </Dropzone>
                              )}

                              <div className="row text-center">
                                <div className="button-section mt-10 mb-20">
                                  <button
                                    className="btn btn-primary button add"
                                    type="button"
                                    onClick={uploadPDF}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="fieldcontainer">
                                <p>PDF Title</p>
                                <input
                                  placeholder="PDF Title"
                                  className="form-control marginTop5"
                                  type="text"
                                  name="pdfName"
                                  value={mentorpdfdetials.pdfName}
                                  onChange={ProfileDetailsonChange}
                                />
                                <p>Course Name</p>
                                <input
                                  placeholder="Course Name"
                                  className="form-control marginTop5"
                                  type="text"
                                  name="CourseNameLinked"
                                  value={mentorpdfdetials.CourseNameLinked}
                                  onChange={ProfileDetailsonChange}
                                  disabled
                                />
                              </div>
                              <div className="fieldcontainer">
                                <p>PDF Description</p>
                                <textarea
                                  className="form-control"
                                  name="pdfDescription"
                                  value={mentorpdfdetials.pdfDescription}
                                  onChange={ProfileDetailsonChange}
                                />
                              </div>

                              <div className="fieldcontainer">
                                <p>PDF Order</p>
                                <input
                                  placeholder="PDF Order"
                                  className="form-control marginTop5"
                                  type="number"
                                  name="pdfOrder"
                                  value={mentorpdfdetials.pdfOrder}
                                  onChange={ProfileDetailsonChange}
                                />
                              </div>
                              <div
                                style={{ marginTop: 20 }}
                                className="fieldcontainer"
                              >
                                {mentorpdfdetials.pdfLink ? (
                                  <>
                                    <p
                                      style={{
                                        color: "green",
                                        fontSize: 18,
                                        fontWeight: 900,
                                      }}
                                    >
                                      PDF ready to Upload
                                    </p>
                                  </>
                                ) : (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: 18,
                                      fontWeight: 900,
                                    }}
                                  >
                                    please upload PDF before Adding PDF details
                                  </p>
                                )}
                              </div>
                              <div className="row text-center">
                                <div className="button-section mt-10 mb-20">
                                  {mentorpdfdetials.pdfLink ? (
                                    <>
                                      <button
                                        className="btn btn-primary button add"
                                        type="button"
                                        onClick={SubmitMentorPDFEntry}
                                      >
                                        Submit
                                      </button>
                                    </>
                                  ) : (
                                    <button
                                      className="btn btn-primary button add"
                                      type="button"
                                      disabled
                                    >
                                      Submit
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <p>Please select any course to add videos</p>
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Text>
        </Card.Body>
      </Card>
    </Wrapper>
  );
};

export default MentorManageDynamicTabs;
