import { combineReducers } from "@reduxjs/toolkit";
import assessmentSlice from "./features/assessment-slice";
import availableAssessmentSlice from "./features/available-assessment-slice";
import cartSlice from "./features/cart-slice";

import headerSlice from "./features/header-slice";
import profileSlice from "./features/profile-slice";
import searchSlice from "./features/search-slice";
import wishlistSlice from "./features/wishlist-slice";

//export interface RootState {}
const rootReducer = combineReducers({
  search: searchSlice,
  cart: cartSlice,
  wishlist: wishlistSlice,
  header: headerSlice,
  profile: profileSlice,
  assessment: assessmentSlice,
  available_assessments: availableAssessmentSlice,
});

export default rootReducer;
