import { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  UserProfileEvent,
  AppConfigEvent,
} from "../../../../../redux/types/redux-event";
import { updateUserProfile } from "../../../../../services/user-service";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { UPDATE_USER_PROFILE } from "../../../../../redux/features/profile-slice";
import {
  ModalBackground,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "../../common/modal";
import {
  RequiredText,
  FieldGroupContainer,
  DateGroupContainer,
  InputFiled,
  Error,
  ButtonGroup,
  ActionButton,
  RemoveAction,
} from "../../common";

const AddEditCertificationsModal = ({
  closeAction,
  forEdit,
  selectedIndex,
}) => {
  const dispatch = useDispatch();
  // const { certifications } = useSelector(
  //   ({ userProfile: { certifications } }: RootState) => ({
  //     certifications,
  //   })
  // );
  const certifications = useSelector((state) => state.profile.certifications);
  console.log("certifications", certifications);

  const [selectedCertificationsData, setSelectedCertificationsData] = useState(
    !certifications?.length ||
      selectedIndex < 0 ||
      !certifications[selectedIndex]
      ? {
          companyName: "",
          certificationCompletionId: "",
          certificationDuration: "",
          startDate: 0,
          endDate: 0,
        }
      : [...certifications][selectedIndex]
  );

  const [formError, setFormError] = useState({
    companyName: "",
    certificationCompletionId: "",
    certificationDuration: "",
    startDate: "",
    endDate: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const validateData = () => {
    const formErrorData = { ...formError };
    formErrorData.companyName = selectedCertificationsData.companyName
      ? ""
      : "Please enter company name.";
    formErrorData.certificationDuration =
      selectedCertificationsData.certificationDuration
        ? ""
        : "Please enter certification duration.";
    formErrorData.startDate = selectedCertificationsData.startDate
      ? selectedCertificationsData.endDate &&
        selectedCertificationsData.startDate >
          selectedCertificationsData.endDate
        ? `Start date can't be greater than or end date.`
        : ""
      : "Please enter certification start date.";
    formErrorData.endDate = selectedCertificationsData.endDate
      ? ""
      : "Please enter certification end/expected date.";

    setFormError(formErrorData);
    return !(
      formErrorData.companyName ||
      formErrorData.certificationDuration ||
      formError.startDate ||
      formError.endDate
    );
  };

  const updateFromData = (fromData) => {
    setSelectedCertificationsData(fromData);
    validateData();
    if (showErrors) {
      setShowErrors(false);
    }
  };

  const addCertificate = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    const certificationsData = [...certifications, selectedCertificationsData];
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ certifications: certificationsData })
      .then((data) => {
        toast.success(`Certificate added successfully`, {
          position: "top-left",
        });

        dispatch(UPDATE_USER_PROFILE({ certifications: data?.certifications }));
      })
      .catch(() =>
        toast.error(`Unable to add certificate`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const updateCertificate = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    const certificationsData = [...certifications];
    certificationsData[selectedIndex] = selectedCertificationsData;
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ certifications: certificationsData })
      .then((data) => {
        toast.success(`Certificate updated successfully`, {
          position: "top-left",
        });

        dispatch(UPDATE_USER_PROFILE({ certifications: data?.certifications }));
      })
      .catch(() =>
        toast.error(`Unable to update certificate`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const deleteCertificate = () => {
    const certificationsData = [...certifications];
    certificationsData.splice(selectedIndex, 1);
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ certifications: certificationsData })
      .then((data) => {
        toast.success(`Certificate deleted successfully`, {
          position: "top-left",
        });
        dispatch(UPDATE_USER_PROFILE({ certifications: data?.certifications }));
      })
      .catch(() =>
        toast.error(`Unable to delete certificate`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };
  return (
    <ModalBackground>
      <Modal>
        <ModalHeader>
          Certifications{" "}
          <img
            src={"/assets/img/ghimages/close-icon.svg"}
            alt="X"
            onClick={closeAction}
          />
        </ModalHeader>
        <ModalBody>
          <RequiredText>* Indicates required</RequiredText>
          <FieldGroupContainer>
            Company name *
            <InputFiled
              value={selectedCertificationsData.companyName}
              onChange={({ target: { value } }) =>
                updateFromData({
                  ...selectedCertificationsData,
                  companyName: value,
                })
              }
              type="text"
            />
            {showErrors && formError.companyName && (
              <Error>{formError.companyName}</Error>
            )}
          </FieldGroupContainer>
          <FieldGroupContainer>
            Certification completion ID
            <InputFiled
              value={selectedCertificationsData.certificationCompletionId}
              onChange={({ target: { value } }) =>
                updateFromData({
                  ...selectedCertificationsData,
                  certificationCompletionId: value,
                })
              }
              type="text"
            />
          </FieldGroupContainer>
          <FieldGroupContainer>
            Certification duration *
            <InputFiled
              value={selectedCertificationsData.certificationDuration}
              onChange={({ target: { value } }) =>
                updateFromData({
                  ...selectedCertificationsData,
                  certificationDuration: value,
                })
              }
              type="text"
            />
            {showErrors && formError.certificationDuration && (
              <Error>{formError.certificationDuration}</Error>
            )}
          </FieldGroupContainer>
          <DateGroupContainer>
            <FieldGroupContainer>
              Start Date *
              <DatePicker
                selected={
                  selectedCertificationsData.startDate
                    ? new Date(selectedCertificationsData.startDate)
                    : null
                }
                maxDate={new Date()}
                onChange={(date) => {
                  if (date)
                    updateFromData({
                      ...selectedCertificationsData,
                      startDate: Date.parse(`${date}`),
                    });
                }}
                customInput={<InputFiled type="text" />}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={34}
              />
              {showErrors && formError.startDate && (
                <Error>{formError.startDate}</Error>
              )}
            </FieldGroupContainer>
            <FieldGroupContainer>
              End Date *
              <DatePicker
                selected={
                  selectedCertificationsData.endDate
                    ? new Date(selectedCertificationsData.endDate)
                    : null
                }
                onChange={(date) => {
                  if (date)
                    updateFromData({
                      ...selectedCertificationsData,
                      endDate: Date.parse(`${date}`),
                    });
                }}
                customInput={<InputFiled type="text" />}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={34}
              />
              {showErrors && formError.endDate && (
                <Error>{formError.endDate}</Error>
              )}
            </FieldGroupContainer>
          </DateGroupContainer>
        </ModalBody>
        <ModalFooter>
          {forEdit ? (
            <ButtonGroup forEdit={true}>
              <RemoveAction onClick={deleteCertificate}>
                Delete certificate
              </RemoveAction>
              <ActionButton onClick={updateCertificate}>Update</ActionButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup forEdit={false}>
              <ActionButton onClick={addCertificate}>Add</ActionButton>
            </ButtonGroup>
          )}
        </ModalFooter>
      </Modal>
    </ModalBackground>
  );
};

export default memo(AddEditCertificationsModal);
