const admin_menu_data_2 = [
  {
    id: 0,
    title: "Dashboard",
    link: "/admin-dashboard",
  },
  {
    id: 1,
    title: "All Users",
    link: "/admin-all-users",
  },
  {
    id: 2,
    title: "Admin Manage Jobs",
    link: "/admin-add-job",
  },
  {
    id: 4,
    title: "Rewards",
    link: "/campus",
  },
  {
    id: 5,
    title: "Enquiry",
    link: "/about",
  },
  {
    id: 6,
    title: "Course",
    link: "/about",
  },
  {
    id: 7,
    title: "Scheduler",
    link: "/admin-scheduler",
  },
  {
    id: 8,
    title: "Admin Messages",
    link: "/about",
  },
  {
    id: 9,
    title: "Push Notification",
    link: "/about",
  },
  {
    id: 10,
    title: "Student Mentor Group",
    link: "/admin-add-student-to-mentor",
  },
  {
    id: 11,
    title: "All Premium Users",
    link: "/admin-all-premium-users",
  },
  {
    id: 12,
    title: "Password Reset",
    link: "/admin-password-reset",
  },
  {
    id: 13,
    title: "User Last Login",
    link: "/admin-user-last-login-details",
  },
  {
    id: 14,
    title: "Manage Student Access",
    link: "/admin-manage-student-access",
  },
  {
    id: 15,
    title: "AI Manager",
    link: "/admin-ai-manager",
  },
];

export default admin_menu_data_2;
