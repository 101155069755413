import Cta from "../common/cta/cta";
import CategoryArea from "./category-area";
import HeroArea from "./hero-area";
import Pricing from "./pricing";
import HowItWorks from "./How-it-works";

const index = () => {
  return (
    <>
      {/* hero start */}
      <HeroArea />
      {/* hero end */}

      {/* category start */}
      <CategoryArea />
      {/* category end */}

      {/* banner start */}
      {/* <BannerArea /> */}
      {/* banner end */}

      {/* 
      {<CareersPath />} */}
      <HowItWorks />
      {/* event start */}
      {/* <EventArea /> */}
      {/* event end */}
      {/* Commented till we create blogs and webinars */}
      {/* {<MixEvents/>} */}

      {/* pricing start */}
      <div style={{ marginTop: 40 }}>
        <Pricing />
      </div>
      {/* pricing end */}

      {/* cta start */}
      <Cta />

      {/* cta end */}
    </>
  );
};

export default index;
