import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { menu_data } from "../../data";
import student_menu_data from "../../data/student-menu-data";
import recuiter_menu_data from "../../data/recuiter-menu-data";
import mentor_menu_data from "../../data/mentor-menu-data";
import admin_menu_data from "../../data/admin-menu-data";
const NavMenu = () => {
  const [currentRole, setcurrentRole] = useState("");
  const [currentMenu, setcurrentMenu] = useState("menu_data");

  const _user = localStorage.getItem("user");

  useEffect(() => {
    const user = JSON.parse(_user);
    //console.log("user", user);
    if (user) {
      const currentRoletemp = user.role;
      //console.log("currentRole", currentRoletemp);
      setcurrentRole(currentRoletemp);
      if (currentRoletemp === "ADMIN") {
      } else if (currentRoletemp === "STUDENT") {
        setcurrentMenu(student_menu_data);
      }
    } else {
      //console.log("Reached Here");
      setcurrentRole("GUEST");
    }
  }, []);

  return (
    <>
      {currentRole === "STUDENT" ? (
        <ul>
          {student_menu_data.map((link, index) => (
            <li key={index} className={link.submenu ? "has-dropdown" : ""}>
              <Link to={`${link.link}`}>{link.title}</Link>
              {link.submenu && (
                <ul className="submenu">
                  {link.submenu.map((menu) => (
                    <li key={menu.id}>
                      <Link to={`${menu.link}`}>{menu.title}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      ) : null}

      {currentRole === "ADMIN" ? (
        <ul>
          {admin_menu_data.map((link, index) => (
            <li key={index} className={link.submenu ? "has-dropdown" : ""}>
              <Link to={`${link.link}`}>{link.title}</Link>
              {link.submenu && (
                <ul className="submenu">
                  {link.submenu.map((menu) => (
                    <li key={menu.id}>
                      <Link to={`${menu.link}`}>{menu.title}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      ) : null}

      {currentRole === "EMPLOYER" ? (
        <ul>
          {recuiter_menu_data.map((link, index) => (
            <li key={index} className={link.submenu ? "has-dropdown" : ""}>
              <Link to={`${link.link}`}>{link.title}</Link>
              {link.submenu && (
                <ul className="submenu">
                  {link.submenu.map((menu) => (
                    <li key={menu.id}>
                      <Link to={`${menu.link}`}>{menu.title}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      ) : null}

      {currentRole === "MENTOR" ? (
        <ul>
          {mentor_menu_data.map((link, index) => (
            <li key={index} className={link.submenu ? "has-dropdown" : ""}>
              <Link to={`${link.link}`}>{link.title}</Link>
              {link.submenu && (
                <ul className="submenu">
                  {link.submenu.map((menu) => (
                    <li key={menu.id}>
                      <Link to={`${menu.link}`}>{menu.title}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      ) : null}

      {currentRole === "GUEST" ? (
        <ul>
          {menu_data.map((link, index) => (
            <li key={index} className={link.submenu ? "has-dropdown" : ""}>
              <Link to={`${link.link}`}>{link.title}</Link>
              {link.submenu && (
                <ul className="submenu">
                  {link.submenu.map((menu) => (
                    <li key={menu.id}>
                      <Link to={`${menu.link}`}>{menu.title}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default NavMenu;
