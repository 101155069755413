import React, { useState } from "react";
import Wrapper from "../../layout/wrapper";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  AdminSetStudentAccess,
  AdminSetStudentAccessIsUserPremium_AI,
  AdminSetStudentAccessIsUserPremium_IP,
  AdminSetStudentAccessIsUserPremium_ASS_SOFT,
  AdminSetStudentAccessIsUserPremium_ASS_APTI,
  AdminSetStudentAccessIsUserPremium_RB,
  AdminSetStudentAccessIsUserPremium_G1,
  AdminSetStudentAccessIsUserPremium,
  AdminSetStudentAccessIsUserPremium_branding,
  AdminSetStudentAccessIsUserPremium_communication,
  AdminSetStudentAccessIsUserPremium_career,
  AdminSetStudentAccessIsUserPremium_interview,
} from "../../services/coupon-service";

const AdminManageStudentAccess = () => {
  const [emailList, setEmailList] = useState([]);
  const [emails, setEmails] = useState([]);

  const handleInputChange = (event) => {
    setEmailList(event.target.value);
  };

  const handleAddEmails = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccess(newEmails)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalse = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium: false,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccess(newEmails)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAddEmailsIsUserPremium_AI = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium_AI: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccessIsUserPremium_AI(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalseIsUserPremium_AI = () => {
    const newEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium_AI: false,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccessIsUserPremium_AI(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAddEmailsIsUserPremium_IP = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium_IP: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");

    // Directly make the API call here
    AdminSetStudentAccessIsUserPremium_IP(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalseIsUserPremium_IP = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium_IP: false,
    }));
    setEmails(updatedEmails);

    // Directly make the API call here
    AdminSetStudentAccessIsUserPremium_IP(updatedEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleAddEmailsIsUserPremium_ASS_SOFT = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium_ASS_SOFT: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");

    const result = AdminSetStudentAccessIsUserPremium_ASS_SOFT(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalseIsUserPremium_ASS_SOFT = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium_ASS_SOFT: false,
    }));
    setEmails(updatedEmails);
    const result = AdminSetStudentAccessIsUserPremium_ASS_SOFT(updatedEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAddEmailsIsUserPremium_ASS_APTI = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium_ASS_APTI: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccessIsUserPremium_ASS_APTI(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalseIsUserPremium_ASS_APTI = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium_ASS_APTI: false,
    }));
    setEmails(updatedEmails);
    const result = AdminSetStudentAccessIsUserPremium_ASS_APTI(updatedEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAddEmailsIsUserPremium_RB = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium_RB: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccessIsUserPremium_RB(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalseIsUserPremium_RB = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium_RB: false,
    }));
    setEmails(updatedEmails);
    const result = AdminSetStudentAccessIsUserPremium_RB(updatedEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAddEmailsIsUserPremium = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccessIsUserPremium(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalseIsUserPremium = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium: false,
    }));
    setEmails(updatedEmails);
    const result = AdminSetStudentAccessIsUserPremium(updatedEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAddEmailsBranding = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccessIsUserPremium_branding(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalseBranding = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium: false,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccessIsUserPremium_branding(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleAddEmailsCommunication = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccessIsUserPremium_communication(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalseCommunication = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium: false,
    }));
    setEmails(updatedEmails);
    const result = AdminSetStudentAccessIsUserPremium_communication(
      updatedEmails
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleAddEmailsCarrer = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccessIsUserPremium_career(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalseCareer = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium: false,
    }));
    setEmails(updatedEmails);
    const result = AdminSetStudentAccessIsUserPremium_career(updatedEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleAddEmailsInterview = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
    const result = AdminSetStudentAccessIsUserPremium_interview(newEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSetFalseInterview = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium: false,
    }));
    setEmails(updatedEmails);
    const result = AdminSetStudentAccessIsUserPremium_interview(updatedEmails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Wrapper>
      <div className="container mt-4">
        <div className="row">
          <h2 className="mb-4">Email List with Premium</h2>
          <div className="col-md-4">
            {" "}
            <div className="form-group">
              <textarea
                style={{ minHeight: 300 }}
                className="form-control"
                value={emailList}
                onChange={handleInputChange}
                placeholder="Enter email IDs (one per line)"
                rows={15}
              />
            </div>
          </div>
          <div className="col-md-8">
            <table className="table table-striped carddesign">
              <tr className="thead-dark">
                <th scope="col">No.</th>
                <th scope="col">Email</th>
                <th scope="col">Action</th>
              </tr>
              <tbody>
                {emails.map((emailObj, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{emailObj.email}</td>
                    <td>
                      {emailObj.IsUserPremium ? emailObj.IsUserPremium : null}
                      {emailObj.IsUserPremium_AI
                        ? emailObj.IsUserPremium_AI
                        : null}
                      {emailObj.IsUserPremium_IP
                        ? emailObj.IsUserPremium_IP
                        : null}
                      {emailObj.IsUserPremium_ASS_SOFT
                        ? emailObj.IsUserPremium_ASS_SOFT
                        : null}
                      {emailObj.IsUserPremium_ASS_APTI
                        ? emailObj.IsUserPremium_ASS_APTI
                        : null}
                      {emailObj.IsUserPremium_RB
                        ? emailObj.IsUserPremium_RB
                        : null}
                      {emailObj.IsUserPremium_G1
                        ? emailObj.IsUserPremium_G1
                        : null}
                      {emailObj.IsUserPremium_G2
                        ? emailObj.IsUserPremium_G2
                        : null}
                      {emailObj.IsUserPremium ? emailObj.IsUserPremium : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ padding: 20 }} className="carddesign">
          {/* <div className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>
                Confidence Builder
              </h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmails}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button className="btn btn-danger mr-2" onClick={handleSetFalse}>
                set as False
              </button>
            </div>
          </div> */}
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>AI Interview</h3>
            </div>

            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium_AI}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium_AI}
              >
                set as False
              </button>
            </div>
          </div>
          {/* <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Interview Prep</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium_IP}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium_IP}
              >
                set as False
              </button>
            </div>
          </div> */}
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Interpersonal</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium_ASS_SOFT}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium_ASS_SOFT}
              >
                set as False
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Aptitude</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium_ASS_APTI}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium_ASS_APTI}
              >
                set as False
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Resume</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium_RB}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium_RB}
              >
                Set as False
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>ALL Premium</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium}
              >
                Set as False
              </button>
            </div>
          </div>
          {/* bucket */}
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Branding</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsBranding}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseBranding}
              >
                Set as False
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Communication</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsCommunication}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseCommunication}
              >
                Set as False
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>
                Career counselling
              </h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsCarrer}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseCareer}
              >
                Set as False
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Interview Prep</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsInterview}
              >
                set as True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseInterview}
              >
                Set as False
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AdminManageStudentAccess;
