import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import {
  GetCourseByMentorId,
  AddMentorCourse,
  EditCourseListing,
  DeleteCourseListing,
} from "../../services/mentor-service";
import { getDataFromLocalStorage } from "../../browser-storage";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import * as DOMPurify from "dompurify";
import config from "../../services/config";
import MentorSliderMenu from "./mentorslidermenu";
import { v4 as uuid } from "uuid";
import { GetMentorProfile } from "../../services/mentor-service";
const ManageCourse = () => {
  const editor = useRef(null);
  const [courselisting, setCourseListing] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [mentorname, setmentorname] = useState("");

  let getCourseListingByMentor = async () => {
    // const MentorDetials = getDataFromLocalStorage("mentor-details");
    // console.log(MentorDetials);
    const MentorDetials = getDataFromLocalStorage("user-details");
    console.log(MentorDetials);
    const getmentorname = MentorDetials.fullName;
    const getmentorusername = MentorDetials.userName;
    const profileImgUrl = MentorDetials.profileImgUrl;
    const uniquecoursecode = uuid();
    const currentemail = MentorDetials.userName;
    console.log("uniquecoursecode", uniquecoursecode);
    console.log("currentemail", currentemail);
    setCourseDetails({
      course_createdby_mentor: getmentorname,
      userEmailId: currentemail,
      course_code: uniquecoursecode,
      course_mentor_profileImgUrl: profileImgUrl,
    });

    const SubmitResponse = await GetCourseByMentorId(currentemail)
      .then((resp) => {
        console.log(resp);
        const respreverse = resp.reverse();
        setCourseListing(respreverse);
        GetMentorProfileById(currentemail);
      })
      .catch((err) => console.log(err));
  };

  let GetMentorProfileById = async (currentemail) => {
    const SubmitResponse = await GetMentorProfile(currentemail)
      .then((resp) => {
        console.log("resp", resp);
        const currentResp = resp[0];
        const currentfullname = currentResp.fullName;
        const currentuserEmailId = currentResp.userEmailId;
        const uniquecoursecode = uuid();
        setCourseDetails({
          course_createdby_mentor: currentfullname,
          userEmailId: currentuserEmailId,
          course_code: uniquecoursecode,
        });
      })
      .catch((err) => {
        console.log(err);
        setEditMode(true);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  const [CourseDetails, setCourseDetails] = useState({
    course_code: "",
    course_keyword: "",
    course_title: "",
    course_createdby_mentor: "",
    course_mentor_profileImgUrl: "",
    course_category: "",
    course_duration: "",
    course_description: "",
    course_short_description: "",
    course_price: "Free",
    course_banner_link: "",
    course_student_enrolled: "",
    course_liked: "",
    course_shared: "",
    course_created_by_our_mentor: true,
    course_created_date: "",
    userEmailId: "",
    course_videos_count: "",
    course_videos: [],
  });

  useEffect(() => {
    getCourseListingByMentor();
  }, []);

  let EditCourseHandler = (item) => {
    console.log(item);
    console.log(item.course_code);
    setEditMode(true);
    setCourseDetails({
      course_code: item.course_code,
      course_title: item.course_title,
      course_keyword: item.course_keyword,
      course_category: item.course_category,
      course_createdby_mentor: item.course_createdby_mentor,
      course_duration: item.course_duration,
      course_description: item.course_description,
      course_short_description: item.course_short_description,
      course_price: item.course_price,
      course_banner_link: item.course_banner_link,
      course_videos: item.course_videos,
    });
    setCourseVideosDetials(item.course_videos);
  };

  let DeleteCourseHandler = (item) => {
    console.log(item);
    const course_code = item.course_code;
    DeleteCourseListing(course_code).then((resp) => {
      console.log(resp);
      getCourseListingByMentor();
    });
  };

  let CourseDetailsonChange = (evt) => {
    const value = evt.target.value;
    setCourseDetails({
      ...CourseDetails,
      [evt.target.name]: value,
    });
    console.log(CourseDetails);
  };

  const [CourseVideosDetials, setCourseVideosDetials] = useState([
    {
      video_title: "",
      video_embed_code: "",
      video_description: "",
      video_duration: "",
    },
  ]);

  let CourseVideoshandleChange = (i, e) => {
    let newCourseVideos = [...CourseVideosDetials];
    newCourseVideos[i][e.target.name] = e.target.value;
    CourseVideosDetials[i][e.target.name] = newCourseVideos[i][e.target.name];
    setCourseVideosDetials(CourseVideosDetials);
    setCourseVideosDetials([...CourseVideosDetials]);
    console.log(CourseVideosDetials);
  };

  let addCourseVideosFormFields = () => {
    setCourseVideosDetials([
      ...CourseVideosDetials,
      {
        video_title: "",
        video_embed_code: "",
        video_description: "",
        video_duration: "",
      },
    ]);
  };

  let removeCourseVideosFormFields = (i) => {
    let newCourseVideosValues = [...CourseVideosDetials];
    newCourseVideosValues.splice(i, 1);
    setCourseVideosDetials(newCourseVideosValues);
  };

  let SubmitCourseDetials = async () => {
    // console.log('Course Details', CourseDetails)
    // console.log('Module Details', ModuleDetials)
    // console.log('ModuleVideosDetials', ModuleVideosDetials)
    console.log("CourseVideosDetials", CourseVideosDetials);
    CourseDetails.course_videos = CourseVideosDetials;
    console.log(CourseDetails);

    const SubmitResponse = await AddMentorCourse(CourseDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        window.location.reload();
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  let richtext = (e) => {
    let clean = DOMPurify.sanitize(e);
    setCourseDetails({ ...CourseDetails, course_description: clean });
    console.log("course_description", CourseDetails.course_description);
  };
  let switchtoaddmode = () => {
    setEditMode(false);
  };
  let SubmitEditCourseDetails = async () => {
    const SubmitResponse = await EditCourseListing(CourseDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  return (
    <Wrapper>
      <SEO pageTitle={"Manage Course"} />
      <MentorSliderMenu />

      <Container>
        <Row style={{ marginTop: "50px" }}>
          <Col className="divscroll">
            <Card className="MainCard">
              <div className="section__title-wrapper mb-45">
                <h2 className="section__title">
                  All{" "}
                  <span className="yellow-bg">
                    Course{" "}
                    <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                  </span>
                  Listing
                </h2>
              </div>
              {courselisting.map((item) => {
                return (
                  <Card className="carddesign">
                    <Container className="jobcontainer">
                      <Row>
                        <p className="jobtitle">
                          <b className="boldtitle">
                            Course Title: &nbsp;&nbsp;{item.course_title}
                          </b>
                        </p>
                      </Row>
                      <Row>
                        <p className="companytitle">
                          <img
                            width="50px"
                            src="/assets/img/ghjob/blankcompany.png"
                            style={{ borderRadius: "50%" }}
                          />{" "}
                          &nbsp;&nbsp;Course Created by: &nbsp;{mentorname}
                        </p>
                      </Row>
                      <Row>
                        {/* <Col>
                          <p>
                            Category:&nbsp;
                            {item.course_category}
                          </p>
                        </Col>
                        <Col>
                          <p>
                            Videos Count&nbsp;
                            {item.course_videos_count}
                          </p>
                        </Col> */}

                        {/* <Col>
                          <p>Price:&nbsp; {item.course_price}</p>
                        </Col> */}
                      </Row>
                      <Row>
                        {/* <p>{item.job_description}</p>{' '} */}
                        <p>Description:</p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.course_description,
                          }}
                        />
                      </Row>
                      <Row>
                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => EditCourseHandler(item)}
                          >
                            Edit
                          </button>
                        </Col>
                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => DeleteCourseHandler(item)}
                          >
                            Delete
                          </button>
                        </Col>
                      </Row>
                      <Row></Row>
                    </Container>
                  </Card>
                );
              })}
            </Card>
          </Col>
          <Col>
            <Card className="MainCard">
              {editMode == false ? (
                <>
                  <Col>
                    <div className="section__title-wrapper mb-45">
                      <h2 className="section__title">
                        <span className="yellow-bg">
                          Add{" "}
                          <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                        </span>
                        Course
                      </h2>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Container>
                    <Row>
                      <Col sm={8}>
                        <div className="section__title-wrapper mb-45">
                          <h2 className="section__title">
                            Edit{" "}
                            <span className="yellow-bg">
                              Course{" "}
                              <img
                                src="/assets/img/shape/yellow-bg-2.png"
                                alt=""
                              />
                            </span>
                            Listing
                          </h2>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <button className="e-btn" onClick={switchtoaddmode}>
                          Switch To Add Mode
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
              {/* <input
                placeholder="Job Code"
                className="form-control marginTop5"
                type="text"
                name="job_code"
                value={CourseDetails.job_code}
                onChange={CourseDetailsonChange}
              /> */}
              <Container>
                <div className="MobTextStyle">
                  <input
                    placeholder="Course Title"
                    className="form-control marginTop5"
                    type="text"
                    name="course_title"
                    value={CourseDetails.course_title}
                    onChange={CourseDetailsonChange}
                  />
                  <input
                    placeholder="Course Keyword"
                    className="form-control marginTop5"
                    type="text"
                    name="course_keyword"
                    value={CourseDetails.course_keyword}
                    onChange={CourseDetailsonChange}
                  />
                  <input
                    placeholder="Mentor Name"
                    className="form-control marginTop5"
                    type="text"
                    name="course_createdby_mentor"
                    value={CourseDetails.course_createdby_mentor}
                    disabled
                  />

                  <JoditEditor
                    ref={editor}
                    value={CourseDetails.course_description}
                    config={config}
                    tabIndex={2} // tabIndex of textarea
                    //onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => richtext(newContent)}
                  />
                </div>
                <Card className="carddesign marginTop5">
                  <Card.Body>
                    <Card.Title>Add Course Video</Card.Title>
                    <Card.Text>
                      {CourseVideosDetials.map((element, index) => (
                        <div className="form-inline" key={index}>
                          <input
                            placeholder="Video Title"
                            className="form-control"
                            type="text"
                            name="video_title"
                            value={element.video_title}
                            onChange={(e) => CourseVideoshandleChange(index, e)}
                          />
                          <input
                            placeholder="Add video link"
                            className="form-control"
                            type="text"
                            name="video_embed_code"
                            value={element.video_embed_code}
                            onChange={(e) => CourseVideoshandleChange(index, e)}
                          />

                          {index ? (
                            <button
                              type="button"
                              className="btn btn-primary button remove"
                              onClick={() =>
                                removeCourseVideosFormFields(index)
                              }
                            >
                              Remove
                            </button>
                          ) : null}
                          <hr />
                        </div>
                      ))}
                      <div className="button-section">
                        <button
                          className="e-btn"
                          type="button"
                          onClick={() => addCourseVideosFormFields()}
                        >
                          Add
                        </button>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Container>
              {editMode == false ? (
                <>
                  <button className="e-btn" onClick={SubmitCourseDetials}>
                    Add Submit
                  </button>
                </>
              ) : (
                <button
                  className="e-btn marginTop5"
                  onClick={SubmitEditCourseDetails}
                >
                  Edit Submit
                </button>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default ManageCourse;
