import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs, Controller, EffectFade } from "swiper";

import { Link } from "react-router-dom";
//#whitelabel-slider

const hero_slider_data = [
  {
    bg_img: "/assets/img/WhiteLabled/brochures/1.png",
    sm_title:
      "Get your Dream Job, a Deserving Salary and a Career that Thrives! ",
    title_b_s: "Learn Proven Strategies and Techniques to",
    shape_text: "Ace",
    title_a_s:
      "Any Interview with Expert Guidance from Interview Strategist Depikka Siingh.",
    text: "Meet university,and cultural institutions, who'll share their experience.",
    text_sub:
      "We offer structured and customised guidance and support to help you accelerate your career.",
    btn_text: "Ready to get Started?",
  },
];

const WhiteHeroSlider = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <section className="slider__area p-relative">
        <div className="slider__wrapper swiper-container">
          <div className="swiper-wrapper">
            <Swiper
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              loop={true}
              spaceBetween={0}
              slidesPerView={1}
              freeMode={false}
              watchSlidesProgress={true}
              effect="fade"
              modules={[Controller, FreeMode, EffectFade, Thumbs]}
              className="mySwiper"
            >
              {hero_slider_data.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className="single-slider slider__height slider__overlay d-flex align-items-center"
                      style={{
                        background: `url(${item.bg_img})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: 600,
                      }}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-xxl-7 col-xl-8 col-lg-9 col-md-9 col-sm-10">
                            <div className="slider__content">
                              <span>{item.sm_title}</span>
                              <h4
                                style={{ fontSize: 24 }}
                                className="slider__title"
                              >
                                {item.title_b_s}{" "}
                                <span
                                  style={{ fontSize: 24 }}
                                  className="yellow-bg"
                                >
                                  {item.shape_text}{" "}
                                  <img
                                    src="/assets/img/shape/yellow-bg.png"
                                    alt=""
                                  />{" "}
                                </span>{" "}
                                {item.title_a_s}
                              </h4>
                              {/* <p>{item.text}</p>
                              <p>{item.text_sub}</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhiteHeroSlider;
