import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";

import Table from "react-bootstrap/Table";

import { CSVLink } from "react-csv";

import Wrapper from "../../layout/wrapper";
import {
  GetCouponsByMentorId,
  GetCouponsUsedByStudents,
} from "../../services/mentor-service";
import { getDataFromLocalStorage } from "../../browser-storage";
const MentorCouponsUsedStudentListing = () => {
  const csvLink = useRef();
  const getStudentData = async () => {
    csvLink.current.link.click();
  };

  let ArrayFormat = [];

  let today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  // const [appliedbylist,setAppliedbyList] = useState({job_code: '', job_title: '', number_of_opening: '', listed_by: '', job_function: '', qualification: '', type: '', location: '', start_date: '', salary: '', job_description: '', isremote: '',})
  const [couponslist, setCouponslist] = useState([]);

  useEffect(() => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);

    const currentemail = userDetials.userName;
    const response = GetCouponsUsedByStudents(currentemail).then((resp) => {
      console.log(resp);
      setCouponslist(resp);
    });

    console.log("respose", response);
  }, []);

  return (
    <>
      <Wrapper>
        <Container>
          {/* <button className="e-btn" onClick={getStudentData}>
            Download Coupons List in CSV
          </button> */}
          <CSVLink
            data={couponslist}
            filename="coupons.csv"
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No.</th>
                <th>Coupon Codes</th>
                <th>Student Email Id</th>
                <th>Student Package Selected</th>
              </tr>
            </thead>
            <tbody>
              {couponslist.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.mentor_coupon_code}</td>
                    <td>{item.StudentEmailId}</td>
                    <td>{item.PackageSelected}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </Wrapper>
    </>
  );
};
export default MentorCouponsUsedStudentListing;
